/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Form,
  FormGroup,
  Label,
  InputGroup,
} from "reactstrap"
import { Link } from "react-router-dom"

// Custom Scrollbar
import SimpleBar from "simplebar-react"

// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/03.png"
import servicesIcon4 from "../../assets/images/services-icon/04.png"
import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"
import smimg1 from "../../assets/images/small/img-1.jpg"
import smimg2 from "../../assets/images/small/img-2.jpg"

// Charts
import LineAreaChart from "../AllCharts/apex/lineareachart"
import RadialChart from "../AllCharts/apex/apexdonut"
import Apexdonut from "../AllCharts/apex/apexdonut1"
import SparkLine from "../AllCharts/sparkline/sparkline"
import SparkLine1 from "../AllCharts/sparkline/sparkline1"
import Salesdonut from "../AllCharts/apex/salesdonut"
import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"
import axios from "axios/axios"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import { format } from "date-fns"
import CountUp from "react-countup"

const Dashboard = (props) => {
  const [menu, setMenu] = useState(false)
  const [reload, setReload] = useState(false)
  const [filter, setFilter] = useState({})
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let filterStr = ""
    if (filter.start) {
      filterStr += `startDate=${filter.start}`
    }
    if (filter.end) {
      let end = new Date(filter.end)
      end.setDate(end.getDate() + 1)
      filterStr += `${filterStr.length > 0 ? "&" : ""}endDate=${format(
        end,
        "yyyy-MM-dd"
      )}`
    }
    filterStr += `${filterStr.length > 0 ? "&" : ""}status=delivered`
    setOrders(0)
    axios
      .get(`orders/all${filterStr.length > 0 ? `?${filterStr}` : ""}`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setOrders(
          res.data.orders &&
            res.data.orders.reduce((acc, one) => acc + one.total, 0)
        )
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        //(e)
      })
  }, [reload])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Dashboard</h6>
                {/* <ol className="breadcrumb m-0">
									<li className="breadcrumb-item active">
										Welcome to Veltrix Dashboard
									</li>
								</ol> */}
              </Col>
            </Row>
          </div>
          <Form>
            <Row>
              <Col md={6} xs={12}>
                <FormGroup className="mb-4">
                  <Label>Start</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="Start"
                      value={filter.start}
                      onChange={(dates) => {
                        setFilter({
                          ...filter,
                          start: format(new Date(dates[0]), "yyyy-MM-dd"),
                        })
                        if (filter.end) {
                          setReload(!reload)
                        }
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={6} xs={12}>
                <FormGroup className="mb-4">
                  <Label>End</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="End"
                      value={filter.end}
                      onChange={(dates) => {
                        setFilter({
                          ...filter,
                          end: format(new Date(dates[0]), "yyyy-MM-dd"),
                        })
                        if (filter.start) {
                          setReload(!reload)
                        }
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
          {loading ? (
            <h4 style={{ textAlign: "center" }}>Loading...</h4>
          ) : (
            <Col md={3}>
              {/* <Card className="mini-stat bg-primary text-white">
                                <CardBody>
                                    <Col>
                                        <Row
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <div
                                                className="float-start mini-stat-img"
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <i className="fas fa-dollar-sign fs-1" />
                                            </div>
                                        </Row>
                                        <br />
                                        <Row
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <h4
                                                className="text-uppercase mt-0 text-white-50"
                                                style={{ textAlign: "center" }}
                                            >
                                                Order Revenue
                                            </h4>
                                        </Row>
                                        <Row
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <h1
                                                className="fw-medium"
                                                style={{ textAlign: "center" }}
                                            >
                                                <CountUp
                                                    duration={1}
                                                    start={0}
                                                    end={orders}
                                                    suffix=" EGP"
                                                />
                                                {/* {orders.toLocaleString(
                                                    undefined,
                                                    {
                                                        maximumFractionDigits: 2,
                                                    }
                                                )} */}
              {/* </h1>
                                        </Row>
                                    </Col>
                                </CardBody>
                            </Card>  */}
            </Col>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
