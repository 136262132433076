/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import axios from "axios/axios"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"

const AddEditAdmin = (props) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [error, setError] = useState({})
  // const [access, setAccess] = useState({
  //   all: false,
  //   QrCode: false,
  //   QrGenerate: false,
  //   QrGet: false,
  //   QrReset: false,
  //   CarType: false,
  //   addCartype: false,
  //   getCartypes: false,
  //   editCartypes: false,
  //   CarModel: false,
  //   carModels: false,
  //   getCarModels: false,
  //   editCarModels: false,
  //   Color: false,
  //   AddColor: false,
  //   getColors: false,
  //   editColors: false,
  //   Notification: false,
  //   AddNotification: false,
  //   getNotification: false,
  //   editNotification: false,
  //   Users: false,
  //   banUsers: false,
  //   getUsers: false,
  //   delUsers: false,
  //   Admin: false,
  //   AddAdmin: false,
  //   getAdmin: false,
  //   delAdmin: false,
  // })
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: props.admin,
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter The Name"),
      email: Yup.string().required("Please Enter The Email"),
      password: Yup.string().required("Please Enter The Password"),
    }),
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })
  const handleEdit = () => {
    console.log(props.admin)
    setLoadingSubmit(true)
    setError({})
    axios
      .patch(
        `admins/editAdmin/${props.admin._id}`,
        {
          name: props.admin.name,
          email: props.admin.email,
          password: props.admin.password,
          access: props.admin.access,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoadingSubmit(false)
        props.setReload((prev) => !prev)
        props.setMenu(false)
        setError("")
        props.setAdmin(props.adminInitial)
      })
      .catch((e) => {
        console.log(e)
        setLoadingSubmit(false)
        if (e.response) {
          if (e.response.data && e.response.data.error.includes("E11000")) {
            setError({
              email: "Email or name already in use",
              name: "Email or name already in use",
            })
          }
        }
      })
  }
  const handleSubmit = (values) => {
    console.log(values)
    setLoadingSubmit(true)
    setError({})
    let email = values.email.split("@")[0]
    console.log(email)
    axios
      .post(
        "admins/createAdmin",
        {
          name: values.name,
          email: email + "@dayra-eg.com",
          password: values.password,
          access: props.admin.access,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoadingSubmit(false)
        props.setReload((prev) => !prev)
        props.setMenu(false)
        setError("")
        props.setAdmin(props.adminInitial)
      })
      .catch((e) => {
        console.log(e)
        setLoadingSubmit(false)
        if (e.response) {
          if (e.response.data && e.response.data.message.includes("E11000")) {
            setError({
              email: "Email or name already in use",
              name: "Email or name already in use",
            })
          }
        }
      })
  }

  return (
    <>
      <Form
        className="row g-3 needs-validation"
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault()
          if (!props.edit) {
            validation.handleSubmit()
          } else {
            handleEdit()
          }
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Name
              </Label>
              <Input
                id="name"
                className="form-control"
                type="text"
                placeholder="Name"
                value={props.admin.name}
                invalid={
                  (validation.touched.name && validation.errors.name) ||
                  error.name
                    ? true
                    : false
                }
                onChange={(e) => {
                  props.setAdmin({
                    ...props.admin,
                    name: e.target.value,
                  })
                  validation.handleChange(e)
                }}
              />
              {(validation.touched.name && validation.errors.name) ||
              error.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name || error.name}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                User Name
              </Label>
              <Input
                id="email"
                className="form-control"
                type="text"
                placeholder="Email"
                value={props.admin.email}
                invalid={
                  (validation.touched.email && validation.errors.email) ||
                  error.email
                    ? true
                    : false
                }
                onChange={(e) => {
                  props.setAdmin({
                    ...props.admin,
                    email: e.target.value,
                  })
                  validation.handleChange(e)
                }}
              />
              {(validation.touched.email && validation.errors.email) ||
              error.email ? (
                <FormFeedback type="invalid">
                  {validation.errors.email || error.email}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup className="mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Password
              </Label>
              <Input
                id="password"
                className="form-control"
                type="password"
                placeholder="Password"
                value={props.admin.password}
                invalid={
                  validation.touched.password && validation.errors.password
                    ? true
                    : false
                }
                onChange={(e) => {
                  props.setAdmin({
                    ...props.admin,
                    password: e.target.value,
                  })
                  validation.handleChange(e)
                }}
              />
              {validation.touched.password && validation.errors.password ? (
                <FormFeedback type="invalid">
                  {validation.errors.password}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <h1>Access</h1>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  onChange={() => {
                    if (props.admin.access.all === false) {
                      console.log(props.admin.access.all)
                      let a = props.admin.access
                      a.all = true
                      a.QrCode = true
                      a.QrGenerate = true
                      a.QrGet = true
                      a.QrReset = true
                      a.CarType = true
                      a.getCartypes = true
                      a.editCartypes = true
                      a.addCartype = true
                      a.CarModel = true
                      a.carModels = true
                      a.editCarModels = true
                      a.getCarModels = true
                      a.Color = true
                      a.getColors = true
                      a.AddColor = true
                      a.editColors = true
                      a.Notification = true
                      a.AddNotification = true
                      a.getNotification = true
                      a.editNotification = true
                      a.Users = true
                      a.banUsers = true
                      a.getUsers = true
                      a.delUsers = true
                      a.Admin = true
                      a.addAdmin = true
                      a.editAdmin = true
                      a.getAdmin = true
                      a.Package = true
                      a.addPackage = true
                      a.editPackage = true
                      a.getPackage = true

                      props.setAdmin({
                        ...props.admin,
                        access: a,
                      })
                    } else {
                      let a = props.admin.access
                      a.all = false
                      a.QrCode = false
                      a.QrGenerate = false
                      a.QrGet = false
                      a.QrReset = false
                      a.CarType = false
                      a.getCartypes = false
                      a.editCartypes = false
                      a.addCartype = false
                      a.CarModel = false
                      a.carModels = false
                      a.editCarModels = false
                      a.getCarModels = false
                      a.Color = false
                      a.getColors = false
                      a.AddColor = false
                      a.editColors = false
                      a.Notification = false
                      a.AddNotification = false
                      a.getNotification = false
                      a.editNotification = false
                      a.Users = false
                      a.banUsers = false
                      a.getUsers = false
                      a.delUsers = false
                      a.Admin = false
                      a.addAdmin = false
                      a.editAdmin = false
                      a.getAdmin = false
                      a.Package = false
                      a.addPackage = false
                      a.editPackage = false
                      a.getPackage = false

                      props.setAdmin({
                        ...props.admin,
                        access: a,
                      })
                    }
                  }}
                  style={{
                    color: props.admin.access.all ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.all}
                />
              }
              label="All "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={2}
            style={{
              textAlign: "center",
              borderLeft: "solid",
              borderRight: "solid",
              borderBottom: "solid",
              borderTop: "solid",
            }}
          >
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  size="small"
                  style={{
                    color: props.admin.access.QrCode ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.QrCode}
                  onChange={() => {
                    if (props.admin.access.QrCode == false) {
                      let a = props.admin.access
                      a.QrCode = true
                      a.QrGenerate = true
                      a.QrGet = true
                      a.QrReset = true

                      props.setAdmin({
                        ...props.admin,
                        access: a,
                      })
                    } else {
                      let a = props.admin.access
                      a.QrCode = false
                      a.QrGenerate = false
                      a.QrGet = false
                      a.QrReset = false
                      a.all = false

                      props.setAdmin({
                        ...props.admin,
                        access: a,
                      })
                    }
                  }}
                />
              }
              label="QR Code "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col
            md={2}
            style={{
              textAlign: "center",
              borderRight: "solid",
              borderBottom: "solid",
              borderTop: "solid",
            }}
          >
            <FormControlLabel
              // value={props.admin.access.CarType}
              control={
                <Switch
                  color="warning"
                  size="small"
                  style={{
                    color: props.admin.access.CarType ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.CarType}
                  onChange={() => {
                    if (props.admin.access.CarType == false) {
                      let a = props.admin.access
                      a.CarType = true
                      a.getCartypes = true
                      a.editCartypes = true
                      a.addCartype = true
                      props.setAdmin({
                        ...props.admin,
                        access: a,
                      })
                    } else {
                      let a = props.admin.access
                      a.CarType = false
                      a.getCartypes = false
                      a.editCartypes = false
                      a.addCartype = false
                      a.all = false
                      props.setAdmin({
                        ...props.admin,
                        access: a,
                      })
                    }
                  }}
                />
              }
              label="Car Brand "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col
            md={2}
            style={{
              textAlign: "center",
              borderRight: "solid",
              borderBottom: "solid",
              borderTop: "solid",
            }}
          >
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  size="small"
                  style={{
                    color: props.admin.access.CarModel ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.CarModel}
                  onChange={() => {
                    if (props.admin.access.CarModel == false) {
                      let a = props.admin.access
                      a.CarModel = true
                      a.carModels = true
                      a.editCarModels = true
                      a.getCarModels = true
                      props.setAdmin({
                        ...props.admin,
                        access: a,
                      })
                    } else {
                      let a = props.admin.access
                      a.CarModel = false
                      a.carModels = false
                      a.editCarModels = false
                      a.all = false
                      a.getCarModels = false
                      props.setAdmin({
                        ...props.admin,
                        access: a,
                      })
                    }
                  }}
                />
              }
              label="Car Models "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col
            md={2}
            style={{
              textAlign: "center",
              borderRight: "solid",
              borderBottom: "solid",
              borderTop: "solid",
            }}
          >
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  size="small"
                  style={{
                    color: props.admin.access.Color ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.Color}
                  onChange={() => {
                    if (props.admin.access.Color == false) {
                      let a = props.admin.access
                      a.Color = true
                      a.getColors = true
                      a.AddColor = true
                      a.editColors = true
                      props.setAdmin({
                        ...props.admin,
                        access: a,
                      })
                    } else {
                      let a = props.admin.access
                      a.Color = false
                      a.getColors = false
                      a.AddColor = false
                      a.all = false
                      a.editColors = false
                      props.setAdmin({
                        ...props.admin,
                        access: a,
                      })
                    }
                  }}
                />
              }
              label="Car Colors "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col
            md={2}
            style={{
              textAlign: "center",
              borderRight: "solid",
              borderBottom: "solid",
              borderTop: "solid",
            }}
          >
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  size="small"
                  style={{
                    color: props.admin.access.Notification
                      ? "#f6c04d "
                      : "white",
                  }}
                  checked={props.admin.access.Notification}
                  onChange={() => {
                    if (props.admin.access.Notification == false) {
                      let a = props.admin.access
                      a.Notification = true
                      a.getNotification = true
                      a.AddNotification = true
                      a.editNotification = true
                      props.setAdmin({
                        ...props.admin,
                        access: a,
                      })
                    } else {
                      let a = props.admin.access
                      a.Notification = false
                      a.getNotification = false
                      a.AddNotification = false
                      a.all = false
                      a.editNotification = false
                      props.setAdmin({
                        ...props.admin,
                        access: a,
                      })
                    }
                  }}
                />
              }
              label="Notifications"
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col
            md={2}
            style={{
              textAlign: "center",
              borderRight: "solid",
              borderBottom: "solid",
              borderTop: "solid",
            }}
          >
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  size="small"
                  style={{
                    color: props.admin.access.Users ? "#f6c04d" : "white",
                  }}
                  checked={props.admin.access.Users}
                  onChange={() => {
                    if (props.admin.access.Users == false) {
                      let a = props.admin.access
                      a.Users = true
                      a.banUsers = true
                      a.getUsers = true
                      a.delUsers = true
                      props.setAdmin({
                        ...props.admin,
                        access: a,
                      })
                    } else {
                      let a = props.admin.access
                      a.Users = false
                      a.banUsers = false
                      a.all = false
                      a.getUsers = false
                      a.delUsers = false
                      props.setAdmin({
                        ...props.admin,
                        access: a,
                      })
                    }
                  }}
                />
              }
              label="Users "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed", borderLeft: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.QrGenerate ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.QrGenerate}
                  onChange={() => {
                    let a = props.admin.access
                    a.QrGenerate = !props.admin.access.QrGenerate
                    if (!a.QrGenerate) a.all = false
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Generate QR Code "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.addCartype ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.addCartype}
                  onChange={() => {
                    let a = props.admin.access
                    a.addCartype = !props.admin.access.addCartype
                    if (!a.addCartype) a.all = false
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Add Car Brand "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>{" "}
          <Col md={2} style={{ borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.carModels ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.carModels}
                  onChange={() => {
                    let a = props.admin.access
                    a.carModels = !props.admin.access.carModels
                    if (!a.carModels) a.all = false
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Add Car Model "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.AddColor ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.AddColor}
                  onChange={() => {
                    let a = props.admin.access
                    a.AddColor = !props.admin.access.AddColor
                    if (!a.AddColor) a.all = false
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Add Car Color "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.AddNotification
                      ? "#f6c04d "
                      : "white",
                  }}
                  checked={props.admin.access.AddNotification}
                  onChange={() => {
                    let a = props.admin.access
                    a.AddNotification = !props.admin.access.AddNotification
                    if (!a.AddNotification) a.all = false
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Add Notification "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.getUsers ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.getUsers}
                  onChange={() => {
                    let a = props.admin.access
                    a.getUsers = !props.admin.access.getUsers
                    if (!a.getUsers) a.all = false
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Get Users "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed", borderLeft: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.QrGet ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.QrGet}
                  onChange={() => {
                    let a = props.admin.access
                    a.QrGet = !props.admin.access.QrGet
                    if (!a.QrCode) a.all = false
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Get QR Codes "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.getCartypes
                      ? "#f6c04d "
                      : "white",
                  }}
                  checked={props.admin.access.getCartypes}
                  onChange={() => {
                    let a = props.admin.access
                    a.getCartypes = !props.admin.access.getCartypes
                    if (!a.getCartypes) a.all = false
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Get Car Brands "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.getCarModels
                      ? "#f6c04d "
                      : "white",
                  }}
                  checked={props.admin.access.getCarModels}
                  onChange={() => {
                    let a = props.admin.access
                    a.getCarModels = !props.admin.access.getCarModels
                    if (!a.getCarModels) a.all = false
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Get Car Models "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.getColors ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.getColors}
                  onChange={() => {
                    let a = props.admin.access
                    a.getColors = !props.admin.access.getColors
                    if (!a.getColors) a.all = false
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Get Car Colors "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.getNotification
                      ? "#f6c04d "
                      : "white",
                  }}
                  checked={props.admin.access.getNotification}
                  onChange={() => {
                    let a = props.admin.access
                    a.getNotification = !props.admin.access.getNotification
                    if (!a.getNotification) a.all = false
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Get Notification "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.banUsers ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.banUsers}
                  onChange={() => {
                    let a = props.admin.access
                    a.banUsers = !props.admin.access.banUsers
                    if (a.banUsers) a.getUsers = true
                    else {
                      a.all = false
                    }
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Ban User"
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed", borderLeft: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.QrReset ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.QrReset}
                  onChange={() => {
                    let a = props.admin.access
                    a.QrReset = !props.admin.access.QrReset
                    if (a.QrReset) a.QrGet = true
                    else {
                      a.all = false
                    }
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Reset QR Code "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.editCartypes
                      ? "#f6c04d "
                      : "white",
                  }}
                  checked={props.admin.access.editCartypes}
                  onChange={() => {
                    let a = props.admin.access
                    a.editCartypes = !props.admin.access.editCartypes
                    if (a.editCartypes) a.getCartypes = true
                    else {
                      a.all = false
                    }
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Edit Car Type "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.editCarModels
                      ? "#f6c04d "
                      : "white",
                  }}
                  checked={props.admin.access.editCarModels}
                  onChange={() => {
                    let a = props.admin.access
                    a.editCarModels = !props.admin.access.editCarModels
                    if (a.editCarModels) a.getCarModels = true
                    else {
                      a.all = false
                    }
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Edit Car Model "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.editColors ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.editColors}
                  onChange={() => {
                    let a = props.admin.access
                    a.editColors = !props.admin.access.editColors
                    if (a.editColors) a.getColors = true
                    else {
                      a.all = false
                    }
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Edit Car Color "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.editNotification
                      ? "#f6c04d "
                      : "white",
                  }}
                  checked={props.admin.access.editNotification}
                  onChange={() => {
                    let a = props.admin.access
                    a.editNotification = !props.admin.access.editNotification
                    if (a.editNotification) a.getNotification = true
                    else {
                      a.all = false
                    }
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Edit Notification "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.delUsers ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.delUsers}
                  onChange={() => {
                    let a = props.admin.access
                    a.delUsers = !props.admin.access.delUsers
                    if (a.delUsers) a.getUsers = true
                    else {
                      a.all = false
                    }
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Delete User"
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={2}
            style={{
              textAlign: "center",
              borderLeft: "solid",
              borderRight: "solid",
              borderBottom: "solid",
              borderTop: "solid",
            }}
          >
            <FormControlLabel
              // value={props.admin.access.CarType}
              control={
                <Switch
                  color="warning"
                  size="small"
                  style={{
                    color: props.admin.access.Admin ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.Admin}
                  onChange={() => {
                    if (props.admin.access.Admin == false) {
                      let a = props.admin.access
                      a.Admin = true
                      a.getAdmin = true
                      a.editAdmin = true
                      a.addAdmin = true
                      props.setAdmin({
                        ...props.admin,
                        access: a,
                      })
                    } else {
                      let a = props.admin.access
                      a.Admin = false
                      a.getAdmin = false
                      a.editAdmin = false
                      a.addAdmin = false
                      a.all = false
                      props.setAdmin({
                        ...props.admin,
                        access: a,
                      })
                    }
                  }}
                />
              }
              label="Admin "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col
            md={2}
            style={{
              textAlign: "center",
              borderRight: "solid",
              borderBottom: "solid",
              borderTop: "solid",
            }}
          >
            <FormControlLabel
              // value={props.admin.access.CarType}
              control={
                <Switch
                  color="warning"
                  size="small"
                  style={{
                    color: props.admin.access.Package ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.Package}
                  onChange={() => {
                    if (props.admin.access.Package == false) {
                      let a = props.admin.access
                      a.Package = true
                      a.getPackage = true
                      a.editPackage = true
                      a.addPackage = true
                      props.setAdmin({
                        ...props.admin,
                        access: a,
                      })
                    } else {
                      let a = props.admin.access
                      a.Package = false
                      a.getPackage = false
                      a.editPackage = false
                      a.addPackage = false
                      a.all = false
                      props.setAdmin({
                        ...props.admin,
                        access: a,
                      })
                    }
                  }}
                />
              }
              label="Package "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
        </Row>
        <Row>
          <Col md={2} style={{ borderLeft: "dashed", borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.addAdmin ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.addAdmin}
                  onChange={() => {
                    let a = props.admin.access
                    a.addAdmin = !props.admin.access.addAdmin
                    if (!a.addAdmin) a.all = false
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Add Admin"
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.addPackage ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.addPackage}
                  onChange={() => {
                    let a = props.admin.access
                    a.addPackage = !props.admin.access.addPackage
                    if (!a.addPackage) a.all = false
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Add Package"
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
        </Row>
        <Row>
          <Col md={2} style={{ borderLeft: "dashed", borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.getAdmin ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.getAdmin}
                  onChange={() => {
                    let a = props.admin.access
                    a.getAdmin = !props.admin.access.getAdmin
                    if (!a.getAdmin) a.all = false
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Get Admin "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.getPackage ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.getPackage}
                  onChange={() => {
                    let a = props.admin.access
                    a.getPackage = !props.admin.access.getPackage
                    if (!a.getPackage) a.all = false
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Get Package "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
        </Row>
        <Row>
          <Col md={2} style={{ borderLeft: "dashed", borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.editAdmin ? "#f6c04d " : "white",
                  }}
                  checked={props.admin.access.editAdmin}
                  onChange={() => {
                    let a = props.admin.access
                    a.editAdmin = !props.admin.access.editAdmin
                    if (a.editAdmin) a.getAdmin = true
                    else {
                      a.all = false
                    }
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Edit Admin "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
          <Col md={2} style={{ borderLeft: "dashed", borderRight: "dashed" }}>
            <FormControlLabel
              //   value={free}
              control={
                <Switch
                  color="warning"
                  style={{
                    color: props.admin.access.editPackage
                      ? "#f6c04d "
                      : "white",
                  }}
                  checked={props.admin.access.editPackage}
                  onChange={() => {
                    let a = props.admin.access
                    a.editPackage = !props.admin.access.editPackage
                    if (a.editPackage) a.getPackage = true
                    else {
                      a.all = false
                    }
                    props.setAdmin({
                      ...props.admin,
                      access: a,
                    })
                  }}
                />
              }
              label="Edit Package "
              labelPlacement="start"
              className="mt-4"
            />
          </Col>
        </Row>

        <div className="col-12">
          <button
            className="btn btn-primary"
            type={loadingSubmit ? null : "submit"}
          >
            {loadingSubmit ? (
              <Spinner size={"sm"} color="secondary" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Form>
    </>
  )
}
AddEditAdmin.propTypes = {
  admin: PropTypes.any,
  edit: PropTypes.any,
  setEdit: PropTypes.any,
  setAdmin: PropTypes.any,
  setMenu: PropTypes.any,
  setReload: PropTypes.any,
  adminInitial: PropTypes.any,
}

export default AddEditAdmin
