/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import user1 from "../../../assets/images/users/user-4.jpg"

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")

  useEffect(() => {
    // //(sessionStorage.getItem("admin"))
    if (sessionStorage.getItem("admin")) {
      const obj = sessionStorage.getItem("admin")
      setusername(obj.name)
    }
  }, [props.success])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div
            className="rounded-circle header-profile-user"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "5vh",
              height: "5vh",
            }}
          >
            {sessionStorage.getItem("admin") ? (
              <i className="fas fa-user-cog" style={{ fontSize: "1vw" }}></i>
            ) : (
              <i className="fas fa-headset" style={{ fontSize: "1vw" }}></i>
            )}
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile" className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}
          </Link>
          <div className="dropdown-divider" />
          <Link
            to="/"
            className="dropdown-item"
            onClick={(e) => {
              e.preventDefault()
              sessionStorage.clear()
              window.location.replace("/")
            }}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
