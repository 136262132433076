/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Alert,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import axios from "axios/axios"
import logo from "../../assets/images/Qrs/logo.png"
import left from "../../assets/images/Qrs/left.png"
import right from "../../assets/images/Qrs/right.png"
import Select from "react-select"
// import { QRCodeCanvas } from "qrcode.react"
import QRCode from "qrcode-generator"
// import * as htmlToImage from "html-to-image"
// import ReactDOMServer from "react-dom/server"
import html2canvas from "html2canvas"
import JSZip from "jszip"
import { saveAs } from "file-saver"

// Now you can use the `saveAs` function in your code

// import { QRCodeCanvas } from "qrcode.react"

const AddEditCallCenter = (props) => {
  const [NoOfCodes, setNoOfCodes] = useState("")
  // const [newQrs, setNewQr] = useState([])
  // const downloadQRCode = (e) => {
  //   // e.preventDefault()
  //   let canvas = qrRef.current.querySelector("canvas")
  //   let image = canvas.toDataURL("image/png")
  //   let anchor = document.createElement("a")
  //   anchor.href = image
  //   anchor.download = `qr-code.png`
  //   document.body.appendChild(anchor)
  //   anchor.click()
  //   document.body.removeChild(anchor)
  //   setNoOfCodes("")
  // }

  const qrCodeEncoder = (e) => {
    setNoOfCodes(e.target.value)
  }
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [loadingSubmit1, setLoadingSubmit1] = useState(false)
  const [makes, setMakes] = useState()
  const [models, setModels] = useState()
  const [colors, setColors] = useState()
  const [years, setYears] = useState([{ label: "year", value: "year" }])
  const [error, setError] = useState("")
  useEffect(() => {
    axios
      .get("/admins/allMakes", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        //("makes", res.data.makes)

        setMakes(
          res.data.makes.map((one) => {
            return {
              name: one.name,
              id: one._id,
            }
          })
        )
      })
      .catch((e) => {
        //(e)
        setError(e.response.data.message)
      })
    if (props.selectedCallCenter) {
      //(props.selectedCallCenter)
      axios
        .get(
          `/admins/makesModels?make=${props.selectedCallCenter.make.value}`,
          {
            headers: {
              Authorization: sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          //("makesModels", res.data.models)

          setModels(
            res.data.models.map((one) => {
              return {
                id: one._id,
                name: one.name,
              }
            })
          )
        })
        .catch((e) => {
          setError(e.response.data.message)
          //(e)
        })
    }
    axios
      .get("/admins/allColors", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        //("allColors", res.data.color)

        setColors(
          res.data.color.map((one) => {
            return {
              name: one.name,
              id: one._id,
            }
          })
        )
      })
      .catch((e) => {
        setError(e.response.data.message)
        //(e)
      })
    if (props.selectedCallCenter) {
      //(props.selectedCallCenter)
      axios
        .get(`/admins/modelyears/${props.selectedCallCenter.model.value}`, {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          //("years", res)

          setYears(
            res.data.years.map((one, index) => {
              return {
                name: one,
                id: index,
              }
            })
          )
        })
        .catch((e) => {
          setError(e.response.data.message)
          //(e)
        })
    }
  }, [props.selectedCallCenter])
  // const [loadingSubmit1, setLoadingSubmit1] = useState(false)

  // const exportCSV = () => {
  //   setLoadingSubmit1(true)
  //   axios({
  //     url: "qrCode/downloadQrCodes", // Replace with your backend URL
  //     method: "GET",
  //     responseType: "blob", // Specify the response type as blob
  //     headers: {
  //       Authorization: sessionStorage.getItem("token"),
  //     },
  //   })
  //     .then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]))
  //       const link = document.createElement("a")
  //       link.href = url
  //       link.setAttribute("download", "Codes.zip")
  //       document.body.appendChild(link)
  //       link.click()
  //       document.body.removeChild(link)
  //       setLoadingSubmit1(false)
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading the Excel file:", error)
  //     })
  // }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: props.callCenter,
    validationSchema: Yup.object(
      !props.selectedCallCenter
        ? {
            name: Yup.string().required("Please Enter The Name"),
            email: Yup.string().required("Please Enter The Email"),
            phone: Yup.string()
              .min(11, "The phone number must contain 11 digits")
              .max(11, "The phone number must contain 11 digits")
              .required("Please Enter The Phone Number"),
            password: Yup.string()
              .min(8, "The password must be at least 8 characters")
              .required("Please Enter The Password"),
          }
        : {
            name: Yup.string(),
            email: Yup.string(),
            phone: Yup.string(),
          }
    ),
    onSubmit: () => {
      handleSubmit()
    },
  })

  const handleSubmit = () => {
    handleAdd()
  }
  const handleedit = () => {
    //(props.selectedCallCenter)
    const p0 = props.selectedCallCenter.plateNo
      ? props.selectedCallCenter.plateNo
      : " "
    const p1 = props.selectedCallCenter.plateNo1
      ? props.selectedCallCenter.plateNo1
      : " "
    const p2 = props.selectedCallCenter.plateNo2
      ? props.selectedCallCenter.plateNo2
      : " "
    const p3 = props.selectedCallCenter.plateNo3
      ? props.selectedCallCenter.plateNo3
      : " "
    const p4 = props.selectedCallCenter.plateNo4
      ? props.selectedCallCenter.plateNo4
      : " "
    const p5 = props.selectedCallCenter.plateNo5
      ? props.selectedCallCenter.plateNo5
      : " "
    const p6 = props.selectedCallCenter.plateNo6
      ? props.selectedCallCenter.plateNo6
      : " "
    const p7 = props.selectedCallCenter.plateNo7
      ? props.selectedCallCenter.plateNo7
      : " "

    const plate = p0 + p1 + p2 + p3 + p7 + p6 + p5 + p4
    //(plate)

    setLoadingSubmit1(true)
    axios
      .patch(
        `qrCode/editQrcodeAdmin/${props.selectedCallCenter._id}`,
        {
          editing: props.selectedCallCenter,
          plate: plate,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        setLoadingSubmit1(false)
        props.setSelectedCallCenter("")
        props.setReload((prev) => !prev)
      })
      .catch((e) => {
        //(e)
        setLoadingSubmit1(false)

        if (e.response) {
          if (e.response.data && e.response.data.message.includes("E11000")) {
            setError({ email: "Email already in use" })
          }
        }
      })
  }
  const handleAdd = () => {
    setLoadingSubmit(true)
    axios
      .post(
        "qrCode/createQrCodes",
        {
          codes: NoOfCodes,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        //(res)
        // setLoadingSubmit(false)
        props.setReload((prev) => !prev)
        AddnewQr()
      })
      .catch((e) => {
        setError(e.response.data.message)

        //(e)
        // if (e.response) {
        //   if (e.response.data && e.response.data.includes("E11000")) {
        //     setError({ email: "Email already in use" })
        //   }
        // }
        setLoadingSubmit(false)
      })
  }
  // function download(dataUrl, fileName) {
  //   const a = document.createElement("a")
  //   a.href = dataUrl
  //   a.download = fileName
  //   a.style.display = "none"
  //   document.body.appendChild(a)
  //   a.click()
  //   document.body.removeChild(a)
  // }
  const mapping = (newQ) => {
    const zip = new JSZip()

    //(newQ)
    const promises = newQ.map((qrcode) => {
      return new Promise((resolve, reject) => {
        // Create a QR code instance
        const qr = QRCode(0, "L") // Adjust the error correction level ('L', 'M', 'Q', or 'H') and version as needed

        // Set the data to encode (your ID)
        qr.addData(qrcode._id)

        // Make the QR code image
        qr.make()
        const qrDataUrl = qr.createDataURL(4) // Adjust the size as needed
        // const canvasItem = (
        //   <QRCodeCanvas
        //     id="newqrCode"
        //     value={qrcode._id}
        //     size={75}
        //     bgColor={"#fff"}
        //     level={"H"}
        //   />
        // )
        // const base64 = ReactDOMServer.renderToString(canvasItem)
        // //(qrcode.serial, base64)
        const image = `
          <style>
              body {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 400px;
              }
      
              img {
                  width: 80%
              }
      
              .cont {
                  width: 400px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  border: solid 3px black;
              }
      
              .rect {
                  width: 400px;
                  height: 600px;
                  border-radius: 135px 0 75px 0;
                  background-color: #f8c14d;
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
      
              .text {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: space-between;
              }
      
              .qr {
                margin-bottom: 25px;
                width: 225px;
                height: 225px;
                background-color: #f8c14d;
                color: #f8c14d;
              }
      
              .logo {
                width: 300px;
                margin-bottom: -40px;
              }
      
              .black-thingy {
                  background-color: black;
                  margin-bottom: 10px;
                  border-radius: 1rem;
              }
      
              #first {
                  width: 90px;
                  margin-top: 500px;
                  margin-right: -50px;
              }

              #second {
                  width: 90px;
                  margin-bottom: 500px;
                  margin-left: -50px;
              }
      
              #QrCodeId {
                  font-family: 'Courier New', Courier, monospace;
                  font-size: 15px;
              }
              #contact {
                  font-family: 'Courier New', Courier, monospace;
                  text-align:center;
                font-size:30px;
                color:#000;
                margin-top:20px;
              }
          </style>
          <div class="rect" id="recs">
              <img src=${left} id="first" />
              <div class="text">
                  <img src=${logo} class='logo' />
                  <p id="QrCodeId">ID: ${qrcode.serial}</p>
                  
                  <img src="${qrDataUrl}" id="qrCodeImage" />
                  <p id="contact">Contact Me</p>
                  
              </div>
              <img src=${right} id="second" />
          </div>
      `
        // Create a container element to hold the HTML content
        const container = document.createElement("div")

        container.innerHTML = image // 'image' is your HTML content as a string

        // Append the container to the document's body (or another suitable location)
        document.body.appendChild(container)
        container.style.position = "fixed"
        container.style.left = "-9999vw"
        container.style.top = "-9999vh"
        // //("Container HTML:", container.innerHTML)
        html2canvas(container)
          .then(function (canvas) {
            //(container)
            document.body.removeChild(container)
            // //(document.getElementById("recs"))
            const imageDataUrl = canvas.toDataURL("image/png")
            zip.file(`image_${qrcode._id}.png`, imageDataUrl.split(",")[1], {
              base64: true,
            })

            resolve() // Resolve the promise when image is added to the zip
            // download(imageDataUrl, "my-node.png")
          })
          .catch(function (error) {
            reject(error)
          })
      })
    })
    Promise.all(promises)
      .then(() => {
        zip
          .generateAsync({ type: "blob" })
          .then(function (blob) {
            const zipFileName = "images.zip"
            saveAs(blob, zipFileName) // Using the FileSaver.js library to trigger the download
            setLoadingSubmit(false)
          })
          .catch(function (error) {
            console.error("Error generating zip file:", error)
          })
      })
      .catch(function (error) {
        console.error("Error generating images:", error)
      })
  }
  const AddnewQr = () => {
    axios
      .get(`qrCode/downloadqrCodes/${NoOfCodes}`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        //(res)
        // setNewQr(res.data)
        //(newQrs)
        mapping(res.data)
        // setNewQr(
        //   res.data.codes.map((one) => {
        //     return {
        //       _id: one._id,
        //       name: one.user.username,
        //       email: one.user.email,
        //       phone: one.user.phone,
        //       plateNo: one.plateNum,
        //       model: one.model.name,
        //       color: one.color.name,
        //       scanned: one.scanned,
        //       serial: one.serial,
        //     }
        //   })
        // )
      })
      .catch((e) => {
        //(e)
        setLoadingSubmit(false)
        setError(e.response.data.message)
      })
  }
  return (
    <>
      <Form
        className="row g-3 needs-validation"
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault()
          //   downloadQRCode()
          if (!props.selectedCallCenter) {
            validation.handleSubmit()
          }
        }}
      >
        {error && <Alert color="danger">{error}</Alert>}
        {!props.selectedCallCenter && (
          <Row>
            <Col xs={12} md={6}>
              <FormGroup className="mt-3 mb-3">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  Number Of Codes
                </Label>
                <Row>
                  <Col>
                    <Input
                      id="name"
                      className="form-control"
                      type="number"
                      placeholder="Enter the Quantity"
                      value={NoOfCodes}
                      onChange={qrCodeEncoder}
                    />{" "}
                  </Col>
                  <Col>
                    <button
                      className="btn btn-primary"
                      type={loadingSubmit ? null : "submit"}
                      onClick={handleAdd}
                    >
                      {loadingSubmit ? (
                        <Spinner size={"sm"} color="secondary" />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </Col>
                </Row>
                {validation.touched.NoOfCodes && validation.errors.NoOfCodes ? (
                  <FormFeedback type="invalid">
                    {validation.errors.NoOfCodes}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
        )}
        {props.selectedCallCenter && (
          <Row>
            <Col xs={12} md={6}>
              {" "}
              <FormGroup className="mt-3 mb-3">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  Brand
                </Label>
                <Select
                  id="category"
                  value={
                    props.selectedCallCenter
                      ? props.selectedCallCenter.make
                      : ""
                  }
                  onChange={(value) => {
                    //(value)
                    //(props.selectedCallCenter)

                    if (props.selectedCallCenter) {
                      props.setSelectedCallCenter({
                        ...props.selectedCallCenter,
                        make: value,
                      })
                    }
                  }}
                  options={makes.map((one) => {
                    return {
                      label: one.name,
                      value: one.id,
                    }
                  })}
                  classNamePrefix="select2-selection"
                />
                {validation.touched.make && validation.errors.make ? (
                  <FormFeedback type="invalid">
                    {validation.errors.make}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              {" "}
              <FormGroup className="mt-3 mb-3">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  Model
                </Label>
                <Select
                  id="category"
                  value={
                    props.selectedCallCenter
                      ? props.selectedCallCenter.model
                      : ""
                  }
                  onChange={(value) => {
                    //(value)
                    //(props.selectedCallCenter)

                    if (props.selectedCallCenter) {
                      props.setSelectedCallCenter({
                        ...props.selectedCallCenter,
                        model: value,
                      })
                    }
                  }}
                  options={
                    models &&
                    models.map((one) => {
                      return {
                        label: one.name,
                        value: one.id,
                      }
                    })
                  }
                  classNamePrefix="select2-selection"
                />
                {validation.touched.model && validation.errors.model ? (
                  <FormFeedback type="invalid">
                    {validation.errors.model}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              {" "}
              <FormGroup className="mt-3 mb-3">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  Color
                </Label>
                <Select
                  id="category"
                  value={
                    props.selectedCallCenter
                      ? props.selectedCallCenter.color
                      : ""
                  }
                  onChange={(value) => {
                    //(value)
                    //(props.selectedCallCenter)

                    if (props.selectedCallCenter) {
                      props.setSelectedCallCenter({
                        ...props.selectedCallCenter,
                        color: value,
                      })
                    }
                  }}
                  options={colors.map((one) => {
                    return {
                      label: one.name,
                      value: one.id,
                    }
                  })}
                  classNamePrefix="select2-selection"
                />
                {validation.touched.color && validation.errors.color ? (
                  <FormFeedback type="invalid">
                    {validation.errors.color}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>

            <Col xs={12} md={6}>
              {" "}
              <FormGroup className="mt-3 mb-3">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  Year
                </Label>
                <Select
                  id="category"
                  value={
                    props.selectedCallCenter
                      ? props.selectedCallCenter.year
                      : ""
                  }
                  onChange={(value) => {
                    //(value)
                    //(props.selectedCallCenter)

                    if (props.selectedCallCenter) {
                      props.setSelectedCallCenter({
                        ...props.selectedCallCenter,
                        year: value,
                      })
                    }
                  }}
                  options={years.map((one) => {
                    return {
                      label: one.name,
                      value: one.id,
                    }
                  })}
                  classNamePrefix="select2-selection"
                />
                {validation.touched.year && validation.errors.year ? (
                  <FormFeedback type="invalid">
                    {validation.errors.year}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
            <Col xs={12} md={8}>
              {" "}
              <FormGroup className="mt-3 mb-3">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  Plate Number
                </Label>
                <Row>
                  <Col md={1}>
                    <Input
                      id="Model"
                      className="form-control"
                      type="text"
                      // placeholder="Plate Number"
                      value={
                        props.selectedCallCenter &&
                        props.selectedCallCenter.plateNo7
                      }
                      onChange={(e) => {
                        if (e.target.value.length < 2)
                          if (props.selectedCallCenter) {
                            props.setSelectedCallCenter({
                              ...props.selectedCallCenter,
                              plateNo7: e.target.value,
                            })
                          }
                      }}
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      id="Model"
                      className="form-control"
                      type="text"
                      // placeholder="Plate Number"
                      value={
                        props.selectedCallCenter &&
                        props.selectedCallCenter.plateNo6
                      }
                      onChange={(e) => {
                        if (e.target.value.length < 2)
                          if (props.selectedCallCenter) {
                            props.setSelectedCallCenter({
                              ...props.selectedCallCenter,
                              plateNo6: e.target.value,
                            })
                          }
                      }}
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      id="Model"
                      className="form-control"
                      type="text"
                      // placeholder="Plate Number"
                      value={
                        props.selectedCallCenter &&
                        props.selectedCallCenter.plateNo5
                      }
                      onChange={(e) => {
                        if (e.target.value.length < 2)
                          if (props.selectedCallCenter) {
                            props.setSelectedCallCenter({
                              ...props.selectedCallCenter,
                              plateNo5: e.target.value,
                            })
                          }
                      }}
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      id="Model"
                      className="form-control"
                      type="text"
                      // placeholder="Plate Number"
                      value={
                        props.selectedCallCenter &&
                        props.selectedCallCenter.plateNo4
                      }
                      onChange={(e) => {
                        if (e.target.value.length < 2)
                          if (props.selectedCallCenter) {
                            props.setSelectedCallCenter({
                              ...props.selectedCallCenter,
                              plateNo4: e.target.value,
                            })
                          }
                      }}
                    />
                  </Col>
                  {/* <Col md={1}>
                    <Input
                      id="Model"
                      className="form-control"
                      type="text"
                      // placeholder="Plate Number"
                      value={
                        props.selectedCallCenter &&
                        props.selectedCallCenter.plateNo3
                      }
                      onChange={(e) => {
                        if (e.target.value.length < 2)
                          if (props.selectedCallCenter) {
                            props.setSelectedCallCenter({
                              ...props.selectedCallCenter,
                              plateNo3: e.target.value,
                            })
                          }
                      }}
                    />
                  </Col> */}
                  <Col md={1}></Col>
                  <Col md={1}>
                    <Input
                      id="Model"
                      className="form-control"
                      type="text"
                      // placeholder="Plate Number"
                      value={
                        props.selectedCallCenter &&
                        props.selectedCallCenter.plateNo2
                      }
                      onChange={(e) => {
                        if (e.target.value.length < 2)
                          if (props.selectedCallCenter) {
                            props.setSelectedCallCenter({
                              ...props.selectedCallCenter,
                              plateNo2: e.target.value,
                            })
                          }
                      }}
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      id="Model"
                      className="form-control"
                      type="text"
                      // placeholder="Plate Number"
                      value={
                        props.selectedCallCenter &&
                        props.selectedCallCenter.plateNo1
                      }
                      onChange={(e) => {
                        if (e.target.value.length < 2)
                          if (props.selectedCallCenter) {
                            props.setSelectedCallCenter({
                              ...props.selectedCallCenter,
                              plateNo1: e.target.value,
                            })
                          }
                      }}
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      id="Model"
                      className="form-control"
                      type="text"
                      // placeholder="Plate Number"
                      value={
                        props.selectedCallCenter &&
                        props.selectedCallCenter.plateNo
                      }
                      onChange={(e) => {
                        if (e.target.value.length < 2)
                          if (props.selectedCallCenter) {
                            props.setSelectedCallCenter({
                              ...props.selectedCallCenter,
                              plateNo: e.target.value,
                            })
                          }
                      }}
                    />
                  </Col>
                </Row>
                {validation.touched.plateNo && validation.errors.plateNo ? (
                  <FormFeedback type="invalid">
                    {validation.errors.plateNo}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>

            <Col>
              <button
                className="btn btn-primary"
                // type={loadingSubmit1 ? null : ""}
                onClick={handleedit}
              >
                {loadingSubmit1 ? (
                  <Spinner size={"sm"} color="secondary" />
                ) : (
                  "Edit"
                )}
              </button>
            </Col>
          </Row>
        )}

        {/* <div className="col-12">
          <button
            className="btn btn-primary"
            type={loadingSubmit1 ? null : "submit"}
            onClick={exportCSV}
          >
            {loadingSubmit1 ? (
              <Spinner size={"sm"} color="secondary" />
            ) : (
              "download"
            )}
          </button>
        </div> */}
      </Form>
    </>
  )
}
AddEditCallCenter.propTypes = {
  callCenter: PropTypes.any,
  selectedCallCenter: PropTypes.any,
  setCallCenter: PropTypes.any,
  setSelectedCallCenter: PropTypes.any,
  setReload: PropTypes.any,
}

export default AddEditCallCenter
