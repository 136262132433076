/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Alert,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import axios from "axios/axios"
// import { SelectionState } from "draft-js"
// import { QRCodeCanvas } from "qrcode.react"

const AddEditPackage = (props) => {
  const [NoOfCodes, setNoOfCodes] = useState("")
  const [body, setBody] = useState("")
  const [price, setPrice] = useState("")
  const [time, setTime] = useState("")
  const [error, setError] = useState("")

  const qrCodeEncoder = (e) => {
    if (props.selectedCallCenter) {
      props.setSelectedCallCenter({
        ...props.selectedCallCenter,
        title: e.target.value,
      })
    } else {
      setNoOfCodes(e.target.value)
    }
  }
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: props.callCenter,
    validationSchema: Yup.object(
      !props.selectedCallCenter
        ? {
            name: Yup.string().required("Please Enter The Name"),
            email: Yup.string().required("Please Enter The Email"),
            phone: Yup.string()
              .min(11, "The phone number must contain 11 digits")
              .max(11, "The phone number must contain 11 digits")
              .required("Please Enter The Phone Number"),
            password: Yup.string()
              .min(8, "The password must be at least 8 characters")
              .required("Please Enter The Password"),
          }
        : {
            name: Yup.string(),
            email: Yup.string(),
            phone: Yup.string(),
          }
    ),
    onSubmit: () => {
      handleSubmit()
    },
  })

  const handleSubmit = () => {
    if (props.selectedCallCenter) {
      //(props.selectedCallCenter)
      //(props.callCenter)
      handleEdit()
    } else handleAdd()
  }
  const handleEdit = () => {
    //(props.selectedCallCenter)

    axios
      .put(
        `/packages/${props.selectedCallCenter._id}`,
        {
          ...(props.callCenter.title != props.selectedCallCenter.title &&
            props.selectedCallCenter.title != "" && {
              name: props.selectedCallCenter.title,
            }),
          ...(props.callCenter.body != props.selectedCallCenter.body &&
            props.selectedCallCenter.body != "" && {
              description: props.selectedCallCenter.body,
            }),
          ...(props.callCenter.price != props.selectedCallCenter.price &&
            props.selectedCallCenter.price != "" && {
              price: props.selectedCallCenter.price,
            }),
          ...(props.callCenter.time != props.selectedCallCenter.time &&
            props.selectedCallCenter.time != "" && {
              time: props.selectedCallCenter.time,
            }),
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        //(res)
        props.setSelectedCallCenter(null)
        props.setReload((prev) => !prev)
      })
      .catch((e) => {
        setError(e.response.data)
      })
  }
  const handleAdd = () => {
    setLoadingSubmit(true)
    axios
      .post(
        "/packages",
        {
          name: NoOfCodes,
          description: body,
          price: price,
          time: time,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        //(res)
        setLoadingSubmit(false)
        setNoOfCodes("")
        setBody("")
        setPrice("")
        setTime("")
        props.setReload((prev) => !prev)
      })
      .catch((e) => {
        //(e)
        setError(e.response.data)
        // if (e.response) {
        // 	if (e.response.data && e.response.data.includes("E11000")) {
        // 		setError({ email: "Email already in use" })
        // 	}
        // }
        setLoadingSubmit(false)
      })
  }

  return (
    <>
      <Form
        className="row g-3 needs-validation"
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault()
          //   downloadQRCode()
          validation.handleSubmit()
        }}
      >
        <Row>
          {error && (
            <Alert color="danger" className="mb-0 mt-4">
              <strong>!</strong> {error}
            </Alert>
          )}
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Title
              </Label>
              <Input
                id="name"
                className="form-control"
                type="text"
                placeholder="Title"
                value={
                  props.selectedCallCenter
                    ? props.selectedCallCenter.title
                    : NoOfCodes
                }
                onChange={qrCodeEncoder}
              />
              {validation.touched.NoOfCodes && validation.errors.NoOfCodes ? (
                <FormFeedback type="invalid">
                  {validation.errors.NoOfCodes}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Body
              </Label>
              <Input
                id="Body"
                className="form-control"
                type="textarea"
                placeholder="Body"
                value={
                  props.selectedCallCenter
                    ? props.selectedCallCenter.body
                    : body
                }
                onChange={(e) => {
                  if (props.selectedCallCenter) {
                    props.setSelectedCallCenter({
                      ...props.selectedCallCenter,
                      body: e.target.value,
                    })
                  } else {
                    setBody(e.target.value)
                  }
                }}
              />
              {validation.touched.body && validation.errors.body ? (
                <FormFeedback type="invalid">
                  {validation.errors.body}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Price
              </Label>
              <Input
                id="name"
                className="form-control"
                type="number"
                placeholder="Price"
                value={
                  props.selectedCallCenter
                    ? props.selectedCallCenter.price
                    : price
                }
                onChange={(e) => {
                  if (props.selectedCallCenter) {
                    props.setSelectedCallCenter({
                      ...props.selectedCallCenter,
                      price: e.target.value,
                    })
                  } else {
                    setPrice(e.target.value)
                  }
                }}
              />
              {validation.touched.price && validation.errors.price ? (
                <FormFeedback type="invalid">
                  {validation.errors.price}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Time/month
              </Label>
              <Input
                id="Body"
                className="form-control"
                type="number"
                placeholder="Time in Month"
                value={
                  props.selectedCallCenter
                    ? props.selectedCallCenter.time
                    : time
                }
                onChange={(e) => {
                  if (props.selectedCallCenter) {
                    props.setSelectedCallCenter({
                      ...props.selectedCallCenter,
                      time: e.target.value,
                    })
                  } else {
                    setTime(e.target.value)
                  }
                }}
              />
              {validation.touched.month && validation.errors.month ? (
                <FormFeedback type="invalid">
                  {validation.errors.month}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <div className="col-12">
          <button
            className="btn btn-primary"
            type={loadingSubmit ? null : "submit"}
            onClick={handleSubmit}
          >
            {loadingSubmit ? (
              <Spinner size={"sm"} color="secondary" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Form>
    </>
  )
}
AddEditPackage.propTypes = {
  callCenter: PropTypes.any,
  selectedCallCenter: PropTypes.any,
  setCallCenter: PropTypes.any,
  setSelectedCallCenter: PropTypes.any,
  setReload: PropTypes.any,
}

export default AddEditPackage
