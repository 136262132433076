/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Label,
  Form,
  FormFeedback,
  Input,
  Spinner,
  Alert,
} from "reactstrap"

// Redux
import { connect, useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, apiError } from "../../store/actions"

// import images
import logoSm from "../../assets/images/dayra_logo_both.png"
import axios from "../../axios/axios"

const Login = () => {
  const credentialsInitial = {
    email: "",
    password: "",
  }
  const [login, setLogin] = useState("admin")
  const [forgotPass, setForgotPass] = useState(false)
  const [hover, setHover] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ email: "", password: "", global: "" })
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: credentialsInitial,
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your User Name"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      setError({ email: "", password: "", global: "" })
      handleSubmit(values)
    },
  })

  const handleSubmit = (values) => {
    setLoading(true)
    if (login === "admin") {
      //(values)
      axios
        .post("admins/login", values)
        .then((res) => {
          //(res.data)
          sessionStorage.setItem("admin", true)
          sessionStorage.setItem("token", res.data.token)
          window.location.replace("/")
        })
        .catch((e) => {
          //(e)
          if (e.response) {
            if (
              e.response.data &&
              (e.response.data.error
                ? e.response.data.error.toLowerCase().includes("email")
                : e.response.data.toLowerCase().includes("email"))
            ) {
              setError({
                password: "",
                global: "",
                email: e.response.data,
              })
            } else if (
              e.response.data &&
              (e.response.data.error
                ? e.response.data.error.toLowerCase().includes("password")
                : e.response.data.toLowerCase().includes("password"))
            ) {
              setError({
                email: "",
                global: "",
                password: e.response.data.error || e.response.data,
              })
            } else {
              setError({
                email: "",
                password: "",
                global: "Something went wrong",
              })
            }
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else if (login === "callCenter") {
      axios
        .post("callCenter/login", values)
        .then((res) => {
          sessionStorage.setItem("callCenter", true)
          sessionStorage.setItem("token", res.data)
          window.location.replace("/")
        })
        .catch((e) => {
          //(e)
          if (e.response) {
            if (
              e.response.data &&
              (e.response.data.error
                ? e.response.data.error.toLowerCase().includes("email")
                : e.response.data.toLowerCase().includes("email"))
            ) {
              setError({
                password: "",
                global: "",
                email: e.response.data,
              })
            } else if (
              e.response.data &&
              (e.response.data.error
                ? e.response.data.error.toLowerCase().includes("password")
                : e.response.data.toLowerCase().includes("password"))
            ) {
              setError({
                email: "",
                global: "",
                password: e.response.data.error || e.response.data,
              })
            } else {
              setError({
                email: "",
                password: "",
                global: "Something went wrong",
              })
            }
          }
          setLoading(false)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div> */}
      <div className="account-pages my-5 pt-sm-5">
        {!login ? (
          <Container>
            <Row className="justify-content-center">
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Card
                  style={{
                    width: "20vw",
                    height: "20vw",
                    cursor: "pointer",
                  }}
                  className={hover === "admin" ? "bg-primary" : "bg-secondary"}
                  onMouseEnter={() => setHover("admin")}
                  onMouseLeave={() => setHover("")}
                  onClick={(e) => {
                    e.preventDefault()
                    setLogin("admin")
                  }}
                >
                  <CardBody
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Col>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <i
                          style={
                            hover === "admin"
                              ? {
                                  color: "#dee2e6",
                                  fontSize: "5vw",
                                }
                              : {
                                  fontSize: "5vw",
                                }
                          }
                          className="fas fa-user-cog"
                        ></i>
                      </div>
                      <br />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <h1
                          style={
                            hover === "admin"
                              ? {
                                  color: "#dee2e6",
                                }
                              : {}
                          }
                        >
                          Admin
                        </h1>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
              {/* <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Card
                  style={{
                    width: "20vw",
                    height: "20vw",
                    cursor: "pointer",
                  }}
                  className={
                    hover === "callCenter" ? "bg-primary" : "bg-secondary"
                  }
                  onMouseEnter={() => setHover("callCenter")}
                  onMouseLeave={() => setHover("")}
                  onClick={(e) => {
                    e.preventDefault()
                    setLogin("callCenter")
                  }}
                >
                  <CardBody
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Col>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <i
                          style={
                            hover === "callCenter"
                              ? {
                                  color: "#dee2e6",
                                  fontSize: "5vw",
                                }
                              : {
                                  fontSize: "5vw",
                                }
                          }
                          className="fas fa-headset"
                        ></i>
                      </div>
                      <br />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <h1
                          style={
                            hover === "callCenter"
                              ? {
                                  color: "#dee2e6",
                                }
                              : {}
                          }
                        >
                          Call Center
                        </h1>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </Col> */}
            </Row>
          </Container>
        ) : forgotPass ? (
          <ForgotPassword setForgotPass={setForgotPass} login={login} />
        ) : (
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={4}>
                <Card className="overflow-hidden">
                  <div className="bg-primary">
                    <div className="text-primary text-center p-4">
                      <h5 className="text-white font-size-20">
                        Welcome Back !
                      </h5>
                      <p className="text-white-50">Sign in.</p>
                      <Link to="/" className="logo logo-admin">
                        <img src={logoSm} height="70" alt="logo" />
                      </Link>
                    </div>
                  </div>

                  <CardBody className="p-4">
                    <div className="p-3">
                      {error.global != "" && (
                        <Alert color="danger" className="mb-0 mt-4">
                          <strong>!</strong> {error.global}
                        </Alert>
                      )}
                      <Form
                        className="mt-4"
                        onSubmit={(e) => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                        action="#"
                      >
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="email">
                            Email
                          </Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter Email"
                            type="email"
                            id="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              (validation.touched.email &&
                                validation.errors.email) ||
                              error.email !== ""
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : error.email !== "" ? (
                            <FormFeedback type="invalid">
                              {error.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label" htmlFor="userpassword">
                            Password
                          </Label>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            className="form-control"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              (validation.touched.password &&
                                validation.errors.password) ||
                              error.password !== ""
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : error.password !== "" ? (
                            <FormFeedback type="invalid">
                              {error.password}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div
                          className="mb-3 row"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {/* <div className="col-sm-6">
														<div className="form-check">
															<input
																type="checkbox"
																className="form-check-input"
																id="customControlInline"
															/>
															<label
																className="form-check-label"
																htmlFor="customControlInline"
															>
																Remember me
															</label>
														</div>
													</div> */}
                          <div
                            className="col-sm-6"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              className="btn btn-primary"
                              type={!loading ? "submit" : null}
                            >
                              {loading ? (
                                <Spinner size={"sm"} color="secondary" />
                              ) : (
                                "Log In"
                              )}
                            </button>
                          </div>
                        </div>

                        {/* <div className="mt-2 mb-0 row">
													<div className="col-12 mt-4">
														<Link to="/forgot-password">
															<i className="mdi mdi-lock"></i> Forgot your
															password?
														</Link>
													</div>
												</div> */}
                      </Form>
                      {/* <div>
                        <p>
                          {login == "admin"
                            ? "Not an admin? "
                            : "Not a call center personnel? "}
                          <Link
                            to="#"
                            onClick={(e) => {
                              e.preventDefault()
                              setLogin(null)
                              setError({
                                email: "",
                                password: "",
                                global: "",
                              })
                            }}
                          >
                            Take me back
                          </Link>
                        </p>
                      </div> */}
                      {/* <div>
                        <p>
                          <Link
                            to="#"
                            onClick={(e) => {
                              e.preventDefault()
                              setForgotPass(true)
                              setError({
                                email: "",
                                password: "",
                                global: "",
                              })
                            }}
                          >
                            Forgot your password?
                          </Link>
                        </p>
                      </div> */}
                    </div>
                  </CardBody>
                </Card>
                {/* <div className="mt-5 text-center">
									<p>
										Don&#39;t have an account ?{" "}
										<Link to="/register" className="fw-medium text-primary">
											{" "}
											Signup now{" "}
										</Link>{" "}
									</p>
									<p>
										© {new Date().getFullYear()} Veltrix. Crafted with{" "}
										<i className="mdi mdi-heart text-danger" /> by Themesbrand
									</p>
								</div> */}
                {/* <div className="mt-5 text-center">
                  <p
                    style={{
                      margin: "0",
                      fontSize: "15px",
                      lineHeight: "1.8",
                      fontWeight: "700",
                      fontFamily: "Segoe UI",
                      color: "#000",
                    }}
                  >
                    Copyright &copy; {new Date().getFullYear()} by{" "}
                    <span>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://neoneg.com/"
                        style={{
                          cursor: "pointer",
                          textDecoration: "none",
                          color: "#000",
                          fontWeight: "700",
                        }}
                      >
                        NEON
                      </a>
                    </span>
                    <a
                      href=""
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        textDecoration: "none",
                        fontWeight: "700",
                        color: "#000",
                      }}
                      onClick={() => {
                        window.open(`https://wa.me/+201113588988`)
                      }}
                    >
                      <i
                        className="fab fa-whatsapp fs-5"
                        style={{
                          color: "#000",
                          marginLeft: "10px",
                        }}
                      ></i>
                    </a>{" "}
                  </p>
                </div> */}
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

const ForgotPassword = ({ setForgotPass, login }) => {
  const [sent, setSent] = useState(false)
  const [loadingForget, setLoadingForget] = useState(false)
  const [errorForget, setErrorForget] = useState()
  const [loadingReset, setLoadingReset] = useState(false)
  const [errorReset, setErrorReset] = useState()
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      setLoadingForget(true)
      axios
        .post(`${login == "admin" ? "admins" : "callCenter"}/forgotPassword`, {
          email: values.email,
        })
        .then(() => {
          setLoadingForget(false)
          setSent(true)
        })
        .catch((e) => {
          setLoadingForget(false)
          setErrorForget(e.response.data)
          //(e)
        })
    },
  })
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={4}>
          <Card className="overflow-hidden">
            <div className="bg-primary">
              <div className="text-primary text-center p-4">
                <h5 className="text-white font-size-20 p-2">Forgot Password</h5>
                <Link to="/" className="logo logo-admin">
                  <img src={logoSm} height="24" alt="logo" />
                </Link>
              </div>
            </div>

            {/* <BarcodeScannerComponent
                            width={500}
                            height={500}
                            onUpdate={(err, result) => {
                                //(result)
                            }}
                        /> */}
            <CardBody className="p-4">
              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
                className="mt-4"
              >
                {sent && (
                  <Alert color="success">
                    Check the email sent to you to complete password reset
                  </Alert>
                )}
                {errorForget && <Alert color="danger">{errorForget}</Alert>}
                <div className="mb-3">
                  <Label className="form-label" htmlor="useremail">
                    Email
                  </Label>
                  <Input
                    name="email"
                    className="form-control"
                    placeholder="Enter email"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      <div>{validation.errors.email}</div>
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="row  mb-0">
                  <div className="col-12 text-end">
                    <button
                      className="btn btn-primary w-md waves-effect waves-light"
                      type={loadingForget ? "" : "submit"}
                    >
                      {loadingForget ? (
                        <Spinner size={"sm"} color="secondary" />
                      ) : (
                        "Reset"
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            </CardBody>
          </Card>
          <div className="mt-5 text-center">
            <p>
              Remember It ?{" "}
              <Link
                to="#"
                className="fw-medium text-primary"
                onClick={(e) => {
                  e.preventDefault()
                  setForgotPass(false)
                }}
              >
                {" "}
                Sign In here{" "}
              </Link>{" "}
            </p>
            <p
              style={{
                margin: "0",
                fontSize: "15px",
                lineHeight: "1.8",
                fontWeight: "700",
                fontFamily: "Segoe UI",
                color: "#000",
              }}
            >
              Copyright &copy; {new Date().getFullYear()} by{" "}
              <span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://neoneg.com/"
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "#000",
                    fontWeight: "700",
                  }}
                >
                  NEON
                </a>
              </span>
              <a
                href=""
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                  fontWeight: "700",
                  color: "#000",
                }}
                onClick={() => {
                  window.open(`https://wa.me/+201113588988`)
                }}
              >
                <i
                  className="fab fa-whatsapp fs-5"
                  style={{
                    color: "#000",
                    marginLeft: "10px",
                  }}
                ></i>
              </a>{" "}
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Login
