/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Form,
  CardHeader,
  Spinner,
  Modal,
  ModalBody,
} from "reactstrap"
import format from "date-fns/"
import { Link } from "react-router-dom"
import { QRCodeCanvas } from "qrcode.react"

import "chartist/dist/scss/chartist.scss"
import AddEditCallCenter from "./AddEditNotification.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//i18n
import { withTranslation } from "react-i18next"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core"
import axios from "axios/axios.js"
import { MDBDataTable } from "mdbreact"

const CallCenter = () => {
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [showStd1, setShowStd1] = useState(false)
  const handleCloseStd1 = () => setShowStd1(!showStd1)
  const [userList, setUserList] = useState([])
  const [sendAll, setSendAll] = useState(false)
  const [users, setUsers] = useState([
    {
      username: "",
      email: "",
      phone: "",
      brand: [],
      make: [],
      fImage: [],
      bImage: [],
      dataApproved: [],
      qrs: [],
    },
  ])

  useEffect(() => {
    axios
      .get("admins/allUsers/", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res.data)
        let b = []
        let m = []
        let fImg = []
        let bImg = []
        let dataApproved = []
        let qrs = []
        setUsers(
          res.data.users.map((one) => {
            b = []
            fImg = []
            bImg = []
            dataApproved = []
            qrs = []
            one.qrCode.map((e) => {
              b.push(e.model && e.model.name)
              fImg.push(e.frontImage)
              bImg.push(e.backImage)
              dataApproved.push(e.dataApproved)
              qrs.push(e._id)
            })
            m = []
            one.qrCode.map((ma) => {
              m.push(ma.model && ma.model.make.name)
            })
            return {
              _id: one._id,
              username: one.username,
              email: one.email,
              phone: one.phone,
              brand: b,
              make: m,
              fImage: fImg,
              bImage: bImg,
              dataApproved: dataApproved,
              qrs: qrs,
              banned: one.banned,
              selected: false,
            }
          })
        )
      })
      .catch((e) => {
        //(e)
      })
  }, [])
  const columns1 = [
    {
      label: "Name",
      field: "username",
      sort: "asc",
    },
    {
      label: "Email",
      field: "email",
      sort: "asc",
    },
    {
      label: "Phone Number",
      field: "phone",
      sort: "asc",
    },
    {
      label: "Car Brand",
      field: "brands",
      sort: "asc",
    },
    {
      label: "Car Model",
      field: "makes",
      sort: "asc",
    },

    {
      label: "License Verfication",
      field: "verf",
      sort: "asc",
    },

    {
      label: "",
      field: "actions",
    },
  ]
  // const exportCSV = () => {
  //   setLoadingSubmit(true)
  //   axios({
  //     url: "qrcode/exportQrCodes", // Replace with your backend URL
  //     method: "GET",
  //     responseType: "blob", // Specify the response type as blob
  //     headers: {
  //       Authorization: sessionStorage.getItem("token"),
  //     },
  //   })
  //     .then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]))
  //       const link = document.createElement("a")
  //       link.href = url
  //       link.setAttribute("download", "Codes.xlsx")
  //       document.body.appendChild(link)
  //       link.click()
  //       document.body.removeChild(link)
  //       setLoadingSubmit(false)
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading the Excel file:", error)
  //     })
  // }
  const columns = [
    // {
    // 	label: "ID",
    // 	field: "_id",
    // 	sort: "asc",
    // },
    {
      label: "Title",
      field: "title",
      sort: "asc",
    },
    {
      label: "Body",
      field: "body",
      sort: "asc",
    },
    {
      label: "",
      // field: "actions",
    },
  ]
  const callCenterInitial = {
    name: "",
    email: "",
    phone: "",
    password: "",
  }
  const [menu, setMenu] = useState(false)
  const [reload, setReload] = useState(false)
  const [callCenters, setCallCenters] = useState([])
  const [callCenter, setCallCenter] = useState(callCenterInitial)
  const [selectedCallCenter, setSelectedCallCenter] = useState()
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    axios
      .get("/notifications/adminNotifications", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        //(res)

        setCallCenters(
          res.data.notifications.map((one) => {
            return {
              _id: one._id,
              title: one.title,
              body: one.body,
              // createdAt: one.createdAt.split("T")[0],
            }
          })
        )
      })
      .catch((e) => {
        //(e)
      })
  }, [reload])

  const handleDelete = (id) => {
    let confirm = window.confirm(
      "You are about to delete this, do you wish to continue?"
    )
    if (confirm) {
      //(sessionStorage.getItem("token"))
      axios
        .delete(`admins/deleteMake/${id}`, {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          //(res)
          setCallCenter(callCenterInitial)
          setSelectedCallCenter(null)
          setReload(!reload)
        })
        .catch((e) => {
          //(e)
        })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h5 className="page-title">Notifications</h5>
              </Col>
            </Row>
          </div>

          <Row className="mb-4">
            <Col>
              <Accordion expanded={menu} style={{ boxShadow: "none" }}>
                <AccordionSummary
                  style={{ backgroundColor: "#E9ECEF" }}
                  onClick={() => {
                    setMenu(!menu)
                  }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h6
                    style={{
                      fontWeight: "bold",
                      color: "#5B626B",
                    }}
                  >
                    {!selectedCallCenter ? "Add" : "Edit"} Notification
                  </h6>
                </AccordionSummary>
                <AccordionDetails>
                  <AddEditCallCenter
                    callCenter={callCenter}
                    selectedCallCenter={selectedCallCenter}
                    setCallCenter={setCallCenter}
                    showStd1={showStd1}
                    setShowStd1={setShowStd1}
                    userList={userList}
                    setUserList={setUserList}
                    setSelectedCallCenter={setSelectedCallCenter}
                    setReload={setReload}
                  />
                </AccordionDetails>
              </Accordion>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h6 style={{ fontWeight: "bold" }}>Notifications List</h6>
                </CardHeader>
                <CardBody>
                  {/* <div className="col-12">
                    <button
                      className="btn btn-primary"
                      style={{ marginBottom: "3vh" }}
                      type={loadingSubmit ? null : "Export"}
                      onClick={exportCSV}
                    >
                      {loadingSubmit ? (
                        <Spinner size={"sm"} color="secondary" />
                      ) : (
                        <>Export</>
                      )}
                    </button>
                  </div> */}
                  <MDBDataTable
                    hover
                    responsive
                    noBottomColumns
                    data={{
                      columns: columns,
                      rows: callCenters.map((one, index) => {
                        return {
                          ...one,
                          actions: (
                            <Row
                              style={{
                                paddingLeft: "1vw",
                                paddingRight: "1vw",
                              }}
                            ></Row>
                          ),
                        }
                      }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={showStd1}
        toggle={handleCloseStd1}
        className="my-modal"
        centered
        size="xl"
      >
        {" "}
        <ModalBody>
          <MDBDataTable
            hover
            responsive
            noBottomColumns
            data={{
              columns: columns1,
              rows: users.map((one, index) => {
                return {
                  ...one,
                  phone: one.phone,
                  brands: (
                    <>
                      <Row>
                        {one.brand.map((b, index) => {
                          return <Row key={index}>{b}</Row>
                        })}
                      </Row>
                    </>
                  ),
                  makes: (
                    <>
                      <Row>
                        {one.make.map((b, index) => {
                          return (
                            <Row key={index}>
                              <Col>{b}</Col>
                            </Row>
                          )
                        })}
                      </Row>
                    </>
                  ),

                  address: one.address
                    ? one.address.apartment +
                      ", " +
                      one.address.street +
                      ", " +
                      one.address.city +
                      ", " +
                      one.address.country +
                      ", " +
                      one.address.zipCode
                    : "",
                  actions: (
                    <Row
                      style={{
                        paddingLeft: "1vw",
                        paddingRight: "1vw",
                      }}
                    >
                      <Col md={6} xs={12}>
                        <Link
                          to="#"
                          className={
                            one.selected
                              ? "btn btn-success btn-sm"
                              : "btn btn-danger btn-sm"
                          }
                          style={{
                            marginLeft: "0.2vw",
                          }}
                          onClick={(e) => {
                            e.preventDefault()

                            if (!one.selected) {
                              let a = users
                              a[index].selected = true
                              setUsers([...a])
                              let newList = userList
                              newList.push(one._id)
                              setUserList([...newList])
                            } else {
                              let a = users
                              a[index].selected = false
                              setUsers([...a])
                              const newArray = userList.filter(
                                (item) => item !== one._id
                              )
                              setUserList([...newArray])
                            }
                          }}
                        >
                          {one.selected ? (
                            <i className="fas fa-check"></i>
                          ) : (
                            <i className="far fa-circle"></i>
                          )}
                        </Link>
                      </Col>
                    </Row>
                  ),
                }
              }),
            }}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

CallCenter.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CallCenter)
