/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Alert,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Switch from "react-switch"
import axios from "axios/axios"
import Dropzone from "react-dropzone"

const AddEditCategory = (props) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [error, setError] = useState()
  const [selectedFile, setSelectedFile] = useState([])
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: props.category,
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter The Name"),
      nameFr: Yup.string().required("Please Enter The Name in French"),
      nameIt: Yup.string().required("Please Enter The Name in Italian"),
      priority: Yup.number()
        .max(6, "Please Enter Number less than 7 and more than zero")
        .min(1, "Please Enter Number less than 6 and more than zero"),
    }),
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function handleAcceptedFiles(file) {
    Object.assign(file[0], {
      preview: URL.createObjectURL(file[0]),
      formattedSize: formatBytes(file[0].size),
    })

    setSelectedFile(file)
  }

  const handleSubmit = (values) => {
    if (props.selectedCategory) {
      handleEdit(values)
    } else {
      handleAdd(values)
    }
  }

  const handleEdit = (values) => {
    setLoadingSubmit(true)
    setError(null)
    var fdata = new FormData()
    fdata.append("name", values.name)
    fdata.append("nameFr", values.nameFr)
    fdata.append("nameIt", values.nameIt)
    fdata.append("priority", values.priority)
    fdata.append(
      "file",
      selectedFile[0] ? selectedFile[0] : props.selectedCategory.image
    )

    axios
      .put(`products/updateCategory/${props.selectedCategory._id}`, fdata, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        props.setReload((prev) => !prev)
        props.setCategory({
          name: "",
          nameFr: "",
          nameIt: "",
        })
        setSelectedFile([])
        props.setSelectedCategory(null)
      })
      .catch((e) => {
        //(e)
        setError("Something went wrong")
      })
      .finally(() => {
        setLoadingSubmit(false)
      })
  }

  const handleAdd = (values) => {
    //("Here")
    setLoadingSubmit(true)
    setError(null)
    var fdata = new FormData()
    fdata.append("file", selectedFile[0])
    fdata.append("name", values.name)
    fdata.append("nameFr", values.nameFr)
    fdata.append("nameIt", values.nameIt)
    fdata.append("priority", values.priority)
    axios
      .post("products/addCategory", fdata, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        props.setReload((prev) => !prev)
        setSelectedFile([])
        props.setCategory({
          name: "",
          nameFr: "",
          nameIt: "",
          image: "",
        })
      })
      .catch((e) => {
        //(e)
        if (e.response) {
          if (e.response.data && e.response.data.error.includes("E11000")) {
            setError("There is a category with this name.")
            return
          }
        }
        setError("Something went wrong")
      })
      .finally(() => {
        setLoadingSubmit(false)
      })
  }

  return (
    <>
      <Form
        className="row g-3 needs-validation"
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault()
          validation.handleSubmit()
        }}
      >
        {error && <Alert color="danger">{error}</Alert>}
        <Row style={{ width: "100%" }}>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Name
              </Label>
              <Input
                required
                id="name"
                className="form-control"
                type="text"
                placeholder="Name"
                value={props.category.name}
                invalid={validation.touched.name && validation.errors.name}
                onChange={(e) => {
                  props.setCategory({
                    ...props.category,
                    name: e.target.value,
                  })
                  validation.handleChange(e)
                }}
              />
              {validation.touched.name && validation.errors.name}
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Name (fr)
              </Label>
              <Input
                required
                id="nameFr"
                className="form-control"
                type="text"
                placeholder="Name (fr)"
                value={props.category.nameFr}
                invalid={validation.touched.nameFr && validation.errors.nameFr}
                onChange={(e) => {
                  props.setCategory({
                    ...props.category,
                    nameFr: e.target.value,
                  })
                  validation.handleChange(e)
                }}
              />
              {validation.touched.nameFr && validation.errors.nameFr}
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Name (it)
              </Label>
              <Input
                required
                id="nameIt"
                className="form-control"
                type="text"
                placeholder="Name (it)"
                value={props.category.nameIt}
                invalid={validation.touched.nameIt && validation.errors.nameIt}
                onChange={(e) => {
                  props.setCategory({
                    ...props.category,
                    nameIt: e.target.value,
                  })
                  validation.handleChange(e)
                }}
              />
              {validation.touched.nameIt && validation.errors.nameIt}
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Priority
              </Label>
              <Input
                required
                id="Priority"
                className="form-control"
                type="number"
                placeholder="Priority"
                value={props.category.priority}
                invalid={
                  validation.touched.priority && validation.errors.priority
                }
                onChange={(e) => {
                  props.setCategory({
                    ...props.category,
                    priority: e.target.value,
                  })
                  validation.handleChange(e)
                }}
              />
              {validation.touched.priority && validation.errors.priority}
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col>
            <FormGroup className="mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Images
              </Label>
              {/* {!props.selectedCategory ? ( */}

              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles)
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                      </div>
                      <h4>Drop files here or click to upload.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
              <Row>
                {/* {props.selectedCategory && //("Omar Sameh")}
                    {props.selectedCategory && (
                      <Col md={2} key={0 + "-file"}>
                        <div className="p-2">
                          <Row
                            className="align-items-center"
                            style={{
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <Col className="col-auto">
                              <i
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  let newSelectedFiles = [...selectedFile]
                                  newSelectedFiles.splice(0, 1)
                                  setSelectedFile(newSelectedFiles)
                                  // let newFiles = [...files]
                                  // newFiles.splice(i, 1)
                                  // setFiles(newFiles)
                                }}
                                className="ion ion-md-close"
                              ></i>
                            </Col>
                          </Row>
                          <Row
                            className="align-items-center"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={props.selectedCategory.name}
                                src={props.selectedCategory.image}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    )} */}
                {selectedFile.map((f, i) => {
                  return (
                    <Col md={2} key={i + "-file"}>
                      <div className="p-2">
                        <Row
                          className="align-items-center"
                          style={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <Col className="col-auto">
                            <i
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                let newSelectedFiles = [...selectedFile]
                                newSelectedFiles.splice(i, 1)
                                setSelectedFile(newSelectedFiles)
                                // let newFiles = [...files]
                                // newFiles.splice(i, 1)
                                // setFiles(newFiles)
                              }}
                              className="ion ion-md-close"
                            ></i>
                          </Col>
                        </Row>
                        <Row
                          className="align-items-center"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Col className="col-auto">
                            {f.type.includes("image") ? (
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            ) : (
                              // <Card
                              // 	style={{
                              // 		height: "80",
                              // 		// display: "flex",
                              // 		// alignItems: "center",
                              // 	}}
                              // >
                              f.name
                              // </Card>
                            )}
                          </Col>
                        </Row>

                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Row>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </FormGroup>
          </Col>
        </Row>

        <div className="col-12">
          <button
            className="btn btn-primary"
            type={loadingSubmit ? null : "submit"}
          >
            {loadingSubmit ? (
              <Spinner size={"sm"} color="secondary" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Form>
    </>
  )
}
AddEditCategory.propTypes = {
  selectedCategory: PropTypes.any,
  setSelectedCategory: PropTypes.any,
  category: PropTypes.any,
  setCategory: PropTypes.any,
  setReload: PropTypes.any,
}

export default AddEditCategory
