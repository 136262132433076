import React from "react"
import ReactImageMagnify from "react-image-magnify"
import PropTypes from "prop-types"

function ZoomableImage({ imageUrl }) {
  const imageProps = {
    smallImage: {
      alt: "Zoomable Image",
      isFluidWidth: true,
      src: imageUrl,
    },
    largeImage: {
      src: imageUrl,
      width: 800, // Adjust the width of the zoomed image as needed
      height: 600, // Adjust the height of the zoomed image as needed
    },
  }
  return (
    <div>
      <ReactImageMagnify {...imageProps} />
    </div>
  )
}
ZoomableImage.propTypes = {
  t: PropTypes.any,
  imageUrl: PropTypes.any,
}
export default ZoomableImage
