/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Form,
  CardHeader,
  Spinner,
} from "reactstrap"
import { Link } from "react-router-dom"
import { QRCodeCanvas } from "qrcode.react"
import html2canvas from "html2canvas"
import logo from "../../assets/images/Qrs/logo.png"
import left from "../../assets/images/Qrs/left.png"
import right from "../../assets/images/Qrs/right.png"
import "chartist/dist/scss/chartist.scss"
import AddEditCallCenter from "./AddEditCallCenter.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//i18n
import { withTranslation } from "react-i18next"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core"
import axios from "axios/axios.js"
import { MDBDataTable } from "mdbreact"
import QRCode from "qrcode-generator"

const CallCenter = () => {
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [edit, setEdit] = useState(false)
  const exportCSV = () => {
    setLoadingSubmit(true)
    axios({
      url: "qrcode/exportQrCodes", // Replace with your backend URL
      method: "GET",
      responseType: "blob", // Specify the response type as blob
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "Codes.xlsx")
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setLoadingSubmit(false)
      })
      .catch((error) => {
        console.error("Error downloading the Excel file:", error)
      })
  }
  const columns = [
    // {
    // 	label: "ID",
    // 	field: "_id",
    // 	sort: "asc",
    // },
    {
      label: "Serial",
      field: "serial",
      sort: "asc",
    },
    {
      label: "Name",
      field: "name",
      sort: "asc",
    },
    {
      label: "Email",
      field: "email",
      sort: "asc",
    },
    {
      label: "Phone Number",
      field: "phone",
      sort: "asc",
    },
    {
      label: "Plate Number",
      field: "plate",
      sort: "asc",
    },
    {
      label: "Subscription",
      field: "subscription",
      sort: "asc",
    },
    {
      label: "Make",
      field: "make",
      sort: "asc",
    },
    {
      label: "Model",
      field: "model",
      sort: "asc",
    },

    {
      label: "Year",
      field: "year",
      sort: "asc",
    },
    {
      label: "Color",
      field: "color",
      sort: "asc",
    },
    {
      label: "Scanned",
      field: "scanned",
      sort: "asc",
    },
    {
      label: "QR's",
      field: "_id",
    },
    {
      label: "",
      field: "actions",
    },
    {
      label: "",
      field: "actions1",
    },
    {
      label: "",
      field: "actions2",
    },
  ]
  const callCenterInitial = {
    name: "",
    email: "",
    phone: "",
    password: "",
  }
  const [menu, setMenu] = useState(false)
  const [reload, setReload] = useState(false)
  const [callCenters, setCallCenters] = useState([])
  const [callCenter, setCallCenter] = useState(callCenterInitial)
  const [selectedCallCenter, setSelectedCallCenter] = useState()
  function download(dataUrl, fileName) {
    const a = document.createElement("a")
    a.href = dataUrl
    a.download = fileName
    a.style.display = "none"
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
  const mapping = (newQ) => {
    console.log(newQ)

    // Create a QR code instance
    const qr = QRCode(0, "L") // Adjust the error correction level ('L', 'M', 'Q', or 'H') and version as needed

    // Set the data to encode (your ID)
    qr.addData(newQ._id)

    // Make the QR code image
    qr.make()
    const qrDataUrl = qr.createDataURL(4) // Adjust the size as needed
    // const canvasItem = (
    //   <QRCodeCanvas
    //     id="newqrCode"
    //     value={qrcode._id}
    //     size={75}
    //     bgColor={"#fff"}
    //     level={"H"}
    //   />
    // )
    // const base64 = ReactDOMServer.renderToString(canvasItem)
    // console.log(qrcode.serial, base64)
    const image = `
          <style>
              body {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 400px;
              }
      
              img {
                  width: 80%
              }
      
              .cont {
                  width: 400px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  border: solid 3px black;
              }
      
              .rect {
                  width: 400px;
                  height: 600px;
                  border-radius: 135px 0 75px 0;
                  background-color: #f8c14d;
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
      
              .text {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: space-between;
              }
      
              .qr {
                margin-bottom: 25px;
                width: 225px;
                height: 225px;
                background-color: #f8c14d;
                color: #f8c14d;
              }
      
              .logo {
                width: 300px;
                margin-bottom: -40px;
              }
      
              .black-thingy {
                  background-color: black;
                  margin-bottom: 10px;
                  border-radius: 1rem;
              }
      
              #first {
                  width: 90px;
                  margin-top: 500px;
                  margin-right: -50px;
              }

              #second {
                  width: 90px;
                  margin-bottom: 500px;
                  margin-left: -50px;
              }
      
              #QrCodeId {
                  font-family: 'Courier New', Courier, monospace;
                  font-size: 15px;
              }
              #contact {
                font-family: 'Courier New', Courier, monospace;
                text-align:center;
              font-size:30px;
              color:#000;
              margin-top:20px;
              }
          </style>
          <div class="rect" id="recs">
              <img src=${left} id="first" />
              <div class="text">
                  <img src=${logo} class='logo' />
                  <p id="QrCodeId">ID: ${newQ.serial}</p>
                  
                  <img src="${qrDataUrl}" id="qrCodeImage" />
                  <p id="contact">Contact Me</p>
                  
              </div>
              <img src=${right} id="second" />
          </div>
      `
    // Create a container element to hold the HTML content
    const container = document.createElement("div")

    container.innerHTML = image // 'image' is your HTML content as a string

    // Append the container to the document's body (or another suitable location)
    document.body.appendChild(container)
    container.style.position = "fixed"
    container.style.left = "-9999vw"
    container.style.top = "-9999vh"
    // console.log("Container HTML:", container.innerHTML)
    html2canvas(container)
      .then(function (canvas) {
        console.log(container)
        document.body.removeChild(container)
        // console.log(document.getElementById("recs"))
        const imageDataUrl = canvas.toDataURL("image/png")
        download(imageDataUrl, "my-node.png")
      })
      .catch(function (error) {
        console.error("Error generating image:", error)
      })
  }
  useEffect(() => {
    axios
      .get("qrcode", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        //(res)
        setCallCenters(
          res.data.codes.map((one) => {
            let Pchar = one.plateNum.split(" ")[0]
            let PNo = one.plateNum.split(" ")[1]

            return {
              _id: one._id,
              name: one.user.username,
              email: one.user.email,
              phone: one.user.phone,
              plate: one.plateNum,
              subscription: one.subscription && one.subscription.packageName,
              plateNo: Pchar ? Pchar[0] : "",
              plateNo1: Pchar ? Pchar[1] : "",
              plateNo2: Pchar ? Pchar[2] : "",
              plateNo3: Pchar ? Pchar[3] : "",
              plateNo4: PNo ? PNo[3] : "",
              plateNo5: PNo ? PNo[2] : "",
              plateNo6: PNo ? PNo[1] : "",
              plateNo7: PNo ? PNo[0] : "",
              model: one.model && {
                label: one.model.name,
                value: one.model._id,
              },
              year: { label: one.year, value: one.year },
              make: one.model && {
                label: one.model.make.name,
                value: one.model.make._id,
              },
              color: one.color && {
                label: one.color.name,
                value: one.color._id,
              },
              scanned: one.scanned,
              serial: one.serial,
            }
          })
        )
      })
      .catch((e) => {
        //(e)
      })
  }, [reload])

  const handleDelete = (id) => {
    let confirm = window.confirm(
      "You are about to delete this, do you wish to continue?"
    )
    if (confirm) {
      //(sessionStorage.getItem("token"))
      axios
        .patch(
          `qrcode/resetcode/${id}`,
          {},
          {
            headers: {
              Authorization: sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          //(res)
          setCallCenter(callCenterInitial)
          setSelectedCallCenter(null)
          setReload(!reload)
        })
        .catch((e) => {
          //(e)
        })
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h5 className="page-title">QR Codes</h5>
              </Col>
            </Row>
          </div>

          <Row className="mb-4">
            <Col>
              <Accordion expanded={menu} style={{ boxShadow: "none" }}>
                <AccordionSummary
                  style={{ backgroundColor: "#E9ECEF" }}
                  onClick={() => {
                    setMenu(!menu)
                  }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h6
                    style={{
                      fontWeight: "bold",
                      color: "#5B626B",
                    }}
                  >
                    {!selectedCallCenter ? "Add" : "Edit"} QR Code
                  </h6>
                </AccordionSummary>
                <AccordionDetails>
                  <AddEditCallCenter
                    callCenter={callCenter}
                    selectedCallCenter={selectedCallCenter}
                    setCallCenter={setCallCenter}
                    setSelectedCallCenter={setSelectedCallCenter}
                    setReload={setReload}
                  />
                </AccordionDetails>
              </Accordion>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h6 style={{ fontWeight: "bold" }}>QR Codes List</h6>
                </CardHeader>
                <CardBody>
                  <div className="col-12">
                    <button
                      className="btn btn-primary"
                      style={{ marginBottom: "3vh" }}
                      type={loadingSubmit ? null : "Export"}
                      onClick={exportCSV}
                    >
                      {loadingSubmit ? (
                        <Spinner size={"sm"} color="secondary" />
                      ) : (
                        <>Export</>
                      )}
                    </button>
                  </div>
                  <MDBDataTable
                    hover
                    responsive
                    noBottomColumns
                    data={{
                      columns: columns,
                      rows: callCenters.map((one) => {
                        return {
                          ...one,
                          _id: (
                            <QRCodeCanvas
                              id="qrCode"
                              value={one._id}
                              size={75}
                              bgColor={"#fff"}
                              level={"H"}
                            />
                          ),
                          model: one.model.label,
                          color: one.color.label,
                          year: one.year.label,
                          make: one.model && one.make.label,
                          actions1: (
                            <Row
                              style={{
                                paddingLeft: "1vw",
                                paddingRight: "1vw",
                              }}
                            >
                              <Col md={6} xs={6}>
                                <Link
                                  to="#"
                                  className="btn btn-danger btn-sm"
                                  style={{
                                    marginLeft: "0.2vw",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    handleDelete(one._id)
                                  }}
                                >
                                  <i className="fas fa-trash"></i>
                                </Link>
                              </Col>
                            </Row>
                          ),
                          actions: (
                            <Row
                              style={{
                                paddingLeft: "1vw",
                                paddingRight: "1vw",
                              }}
                            >
                              {" "}
                              <Col md={6} xs={6}>
                                <Link
                                  to="#"
                                  className="btn btn-success btn-sm"
                                  style={{
                                    marginLeft: "0.2vw",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setCallCenter(one)
                                    setSelectedCallCenter(one)
                                    setEdit(true)
                                    setMenu(true)
                                  }}
                                >
                                  <i className="fas fa-pen"></i>
                                </Link>
                              </Col>
                            </Row>
                          ),
                          actions2: (
                            <Row
                              style={{
                                paddingLeft: "1vw",
                                paddingRight: "1vw",
                              }}
                            >
                              <Col md={6} xs={6}>
                                <Link
                                  to="#"
                                  className="btn btn-primary btn-sm"
                                  style={{
                                    marginLeft: "0.2vw",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    mapping(one)
                                  }}
                                >
                                  <i className="fas fa-download"></i>
                                </Link>
                              </Col>
                            </Row>
                          ),
                        }
                      }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CallCenter.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CallCenter)
