/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Alert,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Select from "react-select"
import axios from "axios/axios"
import { MDBDataTable } from "mdbreact"
import { Link } from "react-router-dom"
// import { isMobile } from "react-device-detect"
import { ModelcolorEN, ModelcolorAR } from "components/ModelcolorStyled"
import { fill } from "lodash"
// import { es } from "date-fns/locale"

// import { QRCodeCanvas } from "qrcode.react"

const AddEditCallCenter = (props) => {
  const [NoOfCodes, setNoOfCodes] = useState("")

  // const downloadQRCode = (e) => {
  //   // e.preventDefault()
  //   let canvas = qrRef.current.querySelector("canvas")
  //   let image = canvas.toDataURL("image/png")
  //   let anchor = document.createElement("a")
  //   anchor.href = image
  //   anchor.download = `qr-code.png`
  //   document.body.appendChild(anchor)
  //   anchor.click()
  //   document.body.removeChild(anchor)
  //   setNoOfCodes("")
  // }

  const qrCodeEncoder = (e) => {
    if (props.selectedCallCenter) {
      props.setSelectedCallCenter({
        ...props.selectedCallCenter,
        name: e.target.value,
      })
    } else {
      setNoOfCodes(e.target.value)
    }
  }
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [loadingSubmit1, setLoadingSubmit1] = useState(false)
  const [year, setYear] = useState()
  const [EndYear, setEndYear] = useState()
  const [color, setColor] = useState()
  const [colorAr, setColorAr] = useState()
  const [error, setError] = useState("")
  // const inputRef = useRef()
  // const [loadingSubmit1, setLoadingSubmit1] = useState(false)

  // const exportCSV = () => {
  //   setLoadingSubmit1(true)
  //   axios({
  //     url: "qrCode/downloadQrCodes", // Replace with your backend URL
  //     method: "GET",
  //     responseType: "blob", // Specify the response type as blob
  //     headers: {
  //       Authorization: sessionStorage.getItem("token"),
  //     },
  //   })
  //     .then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]))
  //       const link = document.createElement("a")
  //       link.href = url
  //       link.setAttribute("download", "Codes.zip")
  //       document.body.appendChild(link)
  //       link.click()
  //       document.body.removeChild(link)
  //       setLoadingSubmit1(false)
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading the Excel file:", error)
  //     })
  // }
  const [callCenters, setCallCenters] = useState([])
  const [selectedCallCenter, setSelectedCallCenter] = useState()
  // const [image, setImage] = useState()
  const [allColors, setAllColors] = useState([])
  const [colorIndex, setColorIndex] = useState()
  const [editColor, setEditColor] = useState()
  const [reload, setReload] = useState(false)
  const columns = [
    // {
    // 	label: "ID",
    // 	field: "_id",
    // 	sort: "asc",
    // },
    {
      label: "Name",
      field: "name",
      sort: "asc",
    },
    {
      label: "Name(Ar)",
      field: "nameAr",
      sort: "asc",
    },
    {
      label: "",
      field: "actions",
    },
  ]
  useEffect(() => {
    axios
      .get("/admins/allMakes", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res)

        setCallCenters(
          res.data.makes.map((one) => {
            return {
              name: one.name,
              id: one._id,
            }
          })
        )
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])
  useEffect(() => {
    axios
      .get("/admins/allColors", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res)

        setAllColors(
          res.data.color.map((one) => {
            return {
              name: one.name,
              nameAr: one.nameAr,
              id: one._id,
            }
          })
        )
      })
      .catch((e) => {
        console.log(e)
      })
  }, [reload])
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: props.callCenter,
    validationSchema: Yup.object(
      !props.selectedCallCenter
        ? {
            name: Yup.string().required("Please Enter The Name"),
            email: Yup.string().required("Please Enter The Email"),
            phone: Yup.string()
              .min(11, "The phone number must contain 11 digits")
              .max(11, "The phone number must contain 11 digits")
              .required("Please Enter The Phone Number"),
            password: Yup.string()
              .min(8, "The password must be at least 8 characters")
              .required("Please Enter The Password"),
          }
        : {
            name: Yup.string(),
            email: Yup.string(),
            phone: Yup.string(),
          }
    ),
    onSubmit: () => {
      handleSubmit()
    },
  })
  const handleEditColor = () => {
    console.log(sessionStorage.getItem("token"))
    axios
      .patch(
        `admins/editColor/${editColor.id}`,
        {
          ...(allColors[colorIndex].name != editColor.name &&
            editColor.name != "" && {
              name: editColor.name,
            }),
          ...(allColors[colorIndex].nameAr != editColor.nameAr &&
            editColor.nameAr != "" && {
              nameAr: editColor.nameAr,
            }),
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res)
        setEditColor(null)
        setReload(!reload)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const handleEdit = () => {
    console.log(sessionStorage.getItem("token"))
    console.log(props.selectedCallCenter)
    var fdata = new FormData()

    if (
      props.callCenter.name != props.selectedCallCenter.name &&
      props.selectedCallCenter.name != ""
    ) {
      fdata.append("name", props.selectedCallCenter.name)
    }
    if (
      props.callCenter.year != props.selectedCallCenter.year &&
      props.selectedCallCenter.year != ""
    ) {
      fdata.append("startYear", props.selectedCallCenter.year)
    }
    if (
      props.callCenter.EndYear != props.selectedCallCenter.EndYear &&
      props.selectedCallCenter.EndYear != ""
    ) {
      fdata.append("endYear", props.selectedCallCenter.EndYear)
    }
    if (
      props.callCenter.makeID.value != props.selectedCallCenter.makeID.value &&
      props.selectedCallCenter.makeID.value != ""
    ) {
      fdata.append("make", props.selectedCallCenter.makeID.value)
    }

    axios
      .patch(`admins/editModel/${props.selectedCallCenter._id}`, fdata, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res)
        props.setSelectedCallCenter(null)
        props.setReload((prev) => !prev)
        setError("")
        // inputRef.current.value = null
        setYear("")
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const handleDelete = (id) => {
    let confirm = window.confirm(
      "You are about to delete this, do you wish to continue?"
    )
    if (confirm) {
      console.log(sessionStorage.getItem("token"))
      axios
        .delete(`admins/deleteColor/${id}`, {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log(res)
          setReload(!reload)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }
  const handleSubmit = () => {
    if (props.selectedCallCenter) {
      console.log(props.selectedCallCenter)
      console.log(props.callCenter)
      handleEdit()
    } else handleAdd()
  }
  const handleSubmitColor = () => {
    if (editColor) {
      handleEditColor()
    } else {
      handleAddColor()
    }
  }
  const handleAdd = () => {
    setLoadingSubmit(true)
    var fdata = new FormData()
    // if (image) {
    //   fdata.append("file", image)
    //   fdata.append("name", NoOfCodes)
    //   fdata.append("make", selectedCallCenter)
    //   fdata.append("year", year)
    // } else {
    fdata.append("name", NoOfCodes)
    fdata.append("make", selectedCallCenter)
    fdata.append("startYear", year)
    fdata.append("endYear", EndYear)
    // }
    axios
      .post(`/admins/createModel`, fdata, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res)
        setLoadingSubmit(false)
        props.setReload((prev) => !prev)
        // inputRef.current.value = null
        setEndYear("")
        setNoOfCodes("")
        setSelectedCallCenter("")
        setError("")
        setYear("")
      })
      .catch((e) => {
        console.log(e)
        // if (e.response) {
        // 	if (e.response.data && e.response.data.includes("E11000")) {
        // 		setError({ email: "Email already in use" })
        // 	}
        // }
        if (e.response.data.message.includes("BSONError"))
          setError("Please Select Car Brand")
        else {
          setError(e.response.data.message)
        }
        setLoadingSubmit(false)
      })
  }
  const handleAddColor = () => {
    setLoadingSubmit1(true)

    axios
      .post(
        `/admins/addColor`,
        { name: color, nameAr: colorAr },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res)
        setLoadingSubmit1(false)
        setReload(!reload)
        setColor("")
        setColorAr("")
        setError("")
        props.setReload((prev) => !prev)
      })
      .catch((e) => {
        console.log(e)
        // if (e.response) {
        // 	if (e.response.data && e.response.data.includes("E11000")) {
        // 		setError({ email: "Email already in use" })
        // 	}
        // }
        if (e.response.data.message.includes(" not authorized "))
          setError("You are not authorized to perform this action")
        else if (e.response.data.message.includes("E11000"))
          setError("Duplicate Color")
        else {
          setError("Missing Fields ")
        }

        setLoadingSubmit1(false)
      })
  }

  return (
    <>
      {console.log(props.selectedCallCenter)}

      <Form
        className="row g-3 needs-validation"
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault()
          //   downloadQRCode()
          validation.handleSubmit()
        }}
      >
        <Row>
          {error && (
            <Alert color="danger" className="mb-0 mt-4">
              <strong>!</strong> {error}
            </Alert>
          )}
          <Col xs={12} md={6} style={{ borderRight: "solid" }}>
            <Row>
              <FormGroup className="mt-3 mb-3">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  Car&apos;s Brand
                </Label>

                <Select
                  id="category"
                  value={
                    props.selectedCallCenter && props.selectedCallCenter.makeID
                  }
                  onChange={(value) => {
                    console.log(value)
                    console.log(props.selectedCallCenter)

                    if (props.selectedCallCenter) {
                      props.setSelectedCallCenter({
                        ...props.selectedCallCenter,
                        makeID: value,
                      })
                    } else {
                      setSelectedCallCenter(value.value)
                    }
                  }}
                  options={callCenters.map((one) => {
                    return {
                      label: one.name,
                      value: one.id,
                    }
                  })}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
            </Row>

            {/* <Row>
              {props.selectedCallCenter && (
                <img
                  src={props.selectedCallCenter.image}
                  style={{ width: "12vw" }}
                ></img>
              )}
              <Col style={{ marginTop: "4vh" }}>
                <FormGroup className="mt-3 mb-3">
                  <Label
                    htmlFor="example-text-input"
                    style={{ color: "#5B626B" }}
                  >
                    {props.selectedCallCenter ? "Edit" : "Choose"} Model Image
                  </Label>
                  <Input
                    id="name"
                    className="form-control"
                    type="file"
                    ref={inputRef}
                    placeholder="Choose Image"
                    onChange={(e) => {
                      setImage(e.target.files[0])
                    }}
                  />
                  {validation.touched.image && validation.errors.image ? (
                    <FormFeedback type="invalid">
                      {validation.errors.image}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
            </Row> */}

            <Row>
              <Col>
                <FormGroup className="mt-3 mb-3">
                  <Label
                    htmlFor="example-text-input"
                    style={{ color: "#5B626B" }}
                  >
                    Car&apos;s Model
                  </Label>
                  <Input
                    id="name"
                    className="form-control"
                    type="text"
                    placeholder="Name"
                    value={
                      props.selectedCallCenter
                        ? props.selectedCallCenter.name
                        : NoOfCodes
                    }
                    onChange={qrCodeEncoder}
                  />
                  {validation.touched.NoOfCodes &&
                  validation.errors.NoOfCodes ? (
                    <FormFeedback type="invalid">
                      {validation.errors.NoOfCodes}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                {" "}
                <FormGroup className="mt-3 mb-3">
                  <Label
                    htmlFor="example-text-input"
                    style={{ color: "#5B626B" }}
                  >
                    Production Starting Year
                  </Label>
                  <Input
                    id="year"
                    className="form-control"
                    type="number"
                    placeholder="Start Year"
                    value={
                      props.selectedCallCenter
                        ? props.selectedCallCenter.year
                        : year
                    }
                    onChange={(e) => {
                      if (props.selectedCallCenter) {
                        props.setSelectedCallCenter({
                          ...props.selectedCallCenter,
                          year: e.target.value,
                        })
                      } else {
                        setYear(e.target.value)
                      }
                    }}
                  />
                  {validation.touched.year && validation.errors.year ? (
                    <FormFeedback type="invalid">
                      {validation.errors.year}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                {" "}
                <FormGroup className="mt-3 mb-3">
                  <Label
                    htmlFor="example-text-input"
                    style={{ color: "#5B626B" }}
                  >
                    Production Ending Year
                  </Label>
                  <Input
                    id="year"
                    className="form-control"
                    type="number"
                    placeholder="End Year"
                    value={
                      props.selectedCallCenter
                        ? props.selectedCallCenter.EndYear
                        : EndYear
                    }
                    onChange={(e) => {
                      if (props.selectedCallCenter) {
                        props.setSelectedCallCenter({
                          ...props.selectedCallCenter,
                          EndYear: e.target.value,
                        })
                      } else {
                        setEndYear(e.target.value)
                      }
                    }}
                  />
                  {validation.touched.EndYear && validation.errors.EndYear ? (
                    <FormFeedback type="invalid">
                      {validation.errors.EndYear}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <div className="col-6">
                <button
                  className="btn btn-primary"
                  type={loadingSubmit ? null : "submit"}
                  onClick={handleSubmit}
                >
                  {loadingSubmit ? (
                    <Spinner size={"sm"} color="secondary" />
                  ) : props.selectedCallCenter ? (
                    "Edit Model"
                  ) : (
                    "Add Model "
                  )}
                </button>
              </div>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <ModelcolorEN>
                <FormGroup className="mt-3 mb-3">
                  <Label
                    htmlFor="example-text-input"
                    style={{ color: "#5B626B" }}
                  >
                    Car Color (EN)
                  </Label>
                  <Input
                    width={fill}
                    id="Color"
                    className="form-control"
                    type="text"
                    placeholder="Modal Color (EN)"
                    value={editColor ? editColor.name : color}
                    onChange={(e) => {
                      if (editColor) {
                        setEditColor({ ...editColor, name: e.target.value })
                      } else setColor(e.target.value)
                    }}
                  />
                  {validation.touched.color && validation.errors.color ? (
                    <FormFeedback type="invalid">
                      {validation.errors.color}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </ModelcolorEN>
              <ModelcolorAR dir="rtl" lang="ar" style={{ marginRight: "3vw" }}>
                <FormGroup className="mt-3 mb-3">
                  <Label
                    htmlFor="example-text-input"
                    style={{ color: "#5B626B" }}
                  >
                    لون السيارة
                  </Label>
                  <Input
                    width={fill}
                    id="Color(AR)"
                    className="form-control"
                    type="text"
                    placeholder="Modal Color (AR)"
                    value={editColor ? editColor.nameAr : colorAr}
                    onChange={(e) => {
                      if (editColor) {
                        setEditColor({ ...editColor, nameAr: e.target.value })
                      } else setColorAr(e.target.value)
                    }}
                  />
                  {validation.touched.color && validation.errors.color ? (
                    <FormFeedback type="invalid">
                      {validation.errors.color}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </ModelcolorAR>
            </Row>
            <div className="col-6">
              <button
                className="btn btn-primary"
                type={loadingSubmit1 ? null : "submit"}
                onClick={handleSubmitColor}
              >
                {loadingSubmit1 ? (
                  <Spinner size={"sm"} color="secondary" />
                ) : (
                  <>{editColor ? "Edit" : "Add"} Color</>
                )}
              </button>
            </div>
            <br />
            <MDBDataTable
              hover
              responsive
              noBottomColumns
              data={{
                columns: columns,
                rows: allColors.map((one, index) => {
                  return {
                    ...one,
                    actions: (
                      <Row
                        style={{
                          paddingLeft: "1vw",
                          paddingRight: "1vw",
                        }}
                      >
                        <Col md={6} xs={6}></Col>
                        <Col md={3} xs={3}>
                          <Link
                            to="#"
                            className="btn btn-danger btn-sm"
                            style={{
                              marginLeft: "0.2vw",
                            }}
                            onClick={(e) => {
                              e.preventDefault()
                              setColorIndex(index)
                              setEditColor(one)
                            }}
                          >
                            <i className="fas fa-pen"></i>
                          </Link>
                        </Col>
                        <Col md={3} xs={3}>
                          <Link
                            to="#"
                            className="btn btn-danger btn-sm"
                            style={{
                              marginLeft: "0.2vw",
                            }}
                            onClick={(e) => {
                              e.preventDefault()
                              handleDelete(one.id)
                            }}
                          >
                            <i className="fas fa-trash"></i>
                          </Link>
                        </Col>
                      </Row>
                    ),
                  }
                }),
              }}
            />
          </Col>
        </Row>
      </Form>
    </>
  )
}
AddEditCallCenter.propTypes = {
  callCenter: PropTypes.any,
  selectedCallCenter: PropTypes.any,
  setCallCenter: PropTypes.any,
  setSelectedCallCenter: PropTypes.any,
  setReload: PropTypes.any,
}

export default AddEditCallCenter
