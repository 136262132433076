import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Routes, Route } from "react-router-dom"
import { connect } from "react-redux"
// import packageJson from "../package.json"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

import fakeBackend from "./helpers/AuthType/fakeBackend"
// import { useEffect } from "react"

// Activating fake backend
fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = () => {
  // const caching = () => {
  //     let version = localStorage.getItem("version")
  //     if (version != packageJson.version) {
  //         if ("caches" in window) {
  //             caches.keys().then((names) => {
  //                 // Delete all the cache files
  //                 names.forEach((name) => {
  //                     caches.delete(name)
  //                 })
  //             })

  //             // Makes sure the page reloads. Changes are only visible after you refresh.
  //             window.location.reload(true)
  //         }

  //         localStorage.clear()
  //         localStorage.setItem("version", packageJson.version)
  //     }
  // }
  useEffect(() => {
    // //(window.location.pathname)
    if (window.location.pathname == "/") {
      if (sessionStorage.getItem("admin")) window.location.replace("/admins")
      else window.location.replace("/products")
    }
  }, [window.location.pathname])
  return (
    <React.Fragment>
      <div>
        <Routes>
          <Route>
            {authRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={<NonAuthLayout>{route.component}</NonAuthLayout>}
                key={idx}
                exact={true}
              />
            ))}
          </Route>

          <Route>
            {userRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={<Authmiddleware>{route.component}</Authmiddleware>}
                key={idx}
                exact={true}
              />
            ))}
          </Route>
        </Routes>
      </div>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
