/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
import PropTypes from "prop-types"
import React, { useState, useRef } from "react"
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Alert,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import axios from "axios/axios"
// import { SelectionState } from "draft-js"
// import { QRCodeCanvas } from "qrcode.react"

const AddEditCallCenter = (props) => {
  const [NoOfCodes, setNoOfCodes] = useState("")
  const [image, setImage] = useState("")
  const inputRef = useRef()
  const [error, setError] = useState("")
  // const downloadQRCode = (e) => {
  //   // e.preventDefault()
  //   let canvas = qrRef.current.querySelector("canvas")
  //   let image = canvas.toDataURL("image/png")
  //   let anchor = document.createElement("a")
  //   anchor.href = image
  //   anchor.download = `qr-code.png`
  //   document.body.appendChild(anchor)
  //   anchor.click()
  //   document.body.removeChild(anchor)
  //   setNoOfCodes("")
  // }

  const qrCodeEncoder = (e) => {
    if (props.selectedCallCenter) {
      props.setSelectedCallCenter({
        ...props.selectedCallCenter,
        name: e.target.value,
      })
    } else {
      setNoOfCodes(e.target.value)
    }
  }
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  // const [loadingSubmit1, setLoadingSubmit1] = useState(false)

  // const exportCSV = () => {
  //   setLoadingSubmit1(true)
  //   axios({
  //     url: "qrCode/downloadQrCodes", // Replace with your backend URL
  //     method: "GET",
  //     responseType: "blob", // Specify the response type as blob
  //     headers: {
  //       Authorization: sessionStorage.getItem("token"),
  //     },
  //   })
  //     .then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]))
  //       const link = document.createElement("a")
  //       link.href = url
  //       link.setAttribute("download", "Codes.zip")
  //       document.body.appendChild(link)
  //       link.click()
  //       document.body.removeChild(link)
  //       setLoadingSubmit1(false)
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading the Excel file:", error)
  //     })
  // }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: props.callCenter,
    validationSchema: Yup.object(
      !props.selectedCallCenter
        ? {
            name: Yup.string().required("Please Enter The Name"),
            email: Yup.string().required("Please Enter The Email"),
            phone: Yup.string()
              .min(11, "The phone number must contain 11 digits")
              .max(11, "The phone number must contain 11 digits")
              .required("Please Enter The Phone Number"),
            password: Yup.string()
              .min(8, "The password must be at least 8 characters")
              .required("Please Enter The Password"),
          }
        : {
            name: Yup.string(),
            email: Yup.string(),
            phone: Yup.string(),
          }
    ),
    onSubmit: () => {
      handleSubmit()
    },
  })

  const handleSubmit = () => {
    if (props.selectedCallCenter) {
      //(props.selectedCallCenter)
      //(props.callCenter)
      handleEdit()
    } else handleAdd()
  }
  const handleEdit = () => {
    //(sessionStorage.getItem("token"))
    var fdata = new FormData()

    if (image) {
      fdata.append("file", image)
      if (
        props.callCenter.name != props.selectedCallCenter.name &&
        props.selectedCallCenter.name != ""
      ) {
        fdata.append("name", props.selectedCallCenter.name)
      }
    } else {
      if (
        props.callCenter.name != props.selectedCallCenter.name &&
        props.selectedCallCenter.name != ""
      ) {
        fdata.append("name", props.selectedCallCenter.name)
      }
    }
    axios
      .patch(`admins/editMake/${props.selectedCallCenter._id}`, fdata, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then(() => {
        //(res)
        props.setSelectedCallCenter(null)
        props.setReload((prev) => !prev)
      })
      .catch((e) => {
        setError(e.response.data)
        //(e)
      })
  }
  const handleAdd = () => {
    setLoadingSubmit(true)
    var fdata = new FormData()
    fdata.append("file", image)
    fdata.append("name", NoOfCodes)
    axios
      .post("/admins/createMake", fdata, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then(() => {
        //(res)
        setLoadingSubmit(false)
        inputRef.current.value = null
        setError("")
        setNoOfCodes("")
        props.setReload((prev) => !prev)
      })
      .catch((e) => {
        //(e)
        setError(e.response.data.message)
        // if (e.response) {
        // 	if (e.response.data && e.response.data.includes("E11000")) {
        // 		setError({ email: "Email already in use" })
        // 	}
        // }
        setLoadingSubmit(false)
      })
  }

  return (
    <>
      <Form
        className="row g-3 needs-validation"
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault()
          //   downloadQRCode()
          validation.handleSubmit()
        }}
      >
        <Row>
          {error && (
            <Alert color="danger" className="mb-0 mt-4">
              <strong>!</strong> {error}
            </Alert>
          )}
          <Col xs={12} md={12}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Car&apos;s Brand
              </Label>
              <Input
                id="name"
                className="form-control"
                type="text"
                placeholder="Name"
                value={
                  props.selectedCallCenter
                    ? props.selectedCallCenter.name
                    : NoOfCodes
                }
                onChange={qrCodeEncoder}
              />
              {validation.touched.NoOfCodes && validation.errors.NoOfCodes ? (
                <FormFeedback type="invalid">
                  {validation.errors.NoOfCodes}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={12} md={12}>
            <Row>
              <Col>
                <FormGroup className="mt-3 mb-3">
                  <Label
                    htmlFor="example-text-input"
                    style={{ color: "#5B626B" }}
                  >
                    {props.selectedCallCenter ? "Edit" : "Choose"} Car Image
                  </Label>
                  <Input
                    id="name"
                    className="form-control"
                    type="file"
                    ref={inputRef}
                    placeholder="Choose Image"
                    onChange={(e) => {
                      setImage(e.target.files[0])
                    }}
                  />
                  {validation.touched.image && validation.errors.image ? (
                    <FormFeedback type="invalid">
                      {validation.errors.image}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
              <Col>
                {props.selectedCallCenter && (
                  <img
                    src={props.selectedCallCenter.image}
                    style={{ width: "12vw" }}
                  ></img>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="col-12">
          <button
            className="btn btn-primary"
            type={loadingSubmit ? null : "submit"}
            onClick={handleSubmit}
          >
            {loadingSubmit ? (
              <Spinner size={"sm"} color="secondary" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Form>
    </>
  )
}
AddEditCallCenter.propTypes = {
  callCenter: PropTypes.any,
  selectedCallCenter: PropTypes.any,
  setCallCenter: PropTypes.any,
  setSelectedCallCenter: PropTypes.any,
  setReload: PropTypes.any,
}

export default AddEditCallCenter
