/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Alert,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import axios from "axios/axios"
// import { SelectionState } from "draft-js"
// import { QRCodeCanvas } from "qrcode.react"

const AddEditCallCenter = (props) => {
  const [error, setError] = useState("")
  const [NoOfCodes, setNoOfCodes] = useState("")

  const [body, setBody] = useState("")
  const qrCodeEncoder = (e) => {
    if (props.selectedCallCenter) {
      props.setSelectedCallCenter({
        ...props.selectedCallCenter,
        name: e.target.value,
      })
    } else {
      setNoOfCodes(e.target.value)
    }
  }
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [loadingSubmit1, setLoadingSubmit1] = useState(false)
  const [loadingSubmit2, setLoadingSubmit2] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: props.callCenter,
    validationSchema: Yup.object(
      !props.selectedCallCenter
        ? {
            name: Yup.string().required("Please Enter The Name"),
            email: Yup.string().required("Please Enter The Email"),
            phone: Yup.string()
              .min(11, "The phone number must contain 11 digits")
              .max(11, "The phone number must contain 11 digits")
              .required("Please Enter The Phone Number"),
            password: Yup.string()
              .min(8, "The password must be at least 8 characters")
              .required("Please Enter The Password"),
          }
        : {
            name: Yup.string(),
            email: Yup.string(),
            phone: Yup.string(),
          }
    ),
    onSubmit: () => {
      handleSubmit()
    },
  })

  const handleSubmit = () => {
    props.setShowStd1(true)
  }
  const handleSubmit1 = () => {
    handleAdd()
  }
  const handleSubmit2 = () => {
    handleAddAll()
  }
  // const handleEdit = () => {
  //   //(sessionStorage.getItem("token"))
  //   axios
  //     .patch(
  //       `admins/editMake/${props.selectedCallCenter._id}`,
  //       {
  //         ...(props.callCenter.name != props.selectedCallCenter.name &&
  //           props.selectedCallCenter.name != "" && {
  //             name: props.selectedCallCenter.name,
  //           }),
  //       },
  //       {
  //         headers: {
  //           Authorization: sessionStorage.getItem("token"),
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       //(res)
  //       props.setSelectedCallCenter(null)
  //       props.setReload((prev) => !prev)
  //     })
  //     .catch((e) => {
  //       //(e)
  //     })
  // }
  const handleAddAll = () => {
    setLoadingSubmit2(true)
    axios
      .post(
        "/notifications/addnotification",
        {
          title: NoOfCodes,
          body: body,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        //(res)
        setLoadingSubmit(false)
        setNoOfCodes("")
        setError("")
        setBody("")
        props.setReload((prev) => !prev)
      })
      .catch((e) => {
        //(e)
        setError(e.response.data)

        // if (e.response) {
        // 	if (e.response.data && e.response.data.includes("E11000")) {
        // 		setError({ email: "Email already in use" })
        // 	}
        // }
        setLoadingSubmit2(false)
      })
  }
  const handleAdd = () => {
    setLoadingSubmit1(true)
    axios
      .post(
        "/notifications/addCustomNotification",
        {
          title: NoOfCodes,
          body: body,
          users: props.userList,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        //(res)
        setLoadingSubmit(false)
        setNoOfCodes("")
        setBody("")
        setError("")

        props.setUserList([])
        props.setReload((prev) => !prev)
      })
      .catch((e) => {
        console.log(e)
        setError(e.response.data.message)

        // if (e.response) {
        // 	if (e.response.data && e.response.data.includes("E11000")) {
        // 		setError({ email: "Email already in use" })
        // 	}
        // }
        setLoadingSubmit1(false)
      })
  }

  return (
    <>
      <Form
        className="row g-3 needs-validation"
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault()
          //   downloadQRCode()
          validation.handleSubmit()
        }}
      >
        <Row>
          {error && (
            <Alert color="danger" className="mb-0 mt-4">
              <strong>!</strong> {error}
            </Alert>
          )}
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Title
              </Label>
              <Input
                id="name"
                className="form-control"
                type="textarea"
                placeholder="Title"
                value={
                  props.selectedCallCenter
                    ? props.selectedCallCenter.name
                    : NoOfCodes
                }
                onChange={qrCodeEncoder}
              />
              {validation.touched.NoOfCodes && validation.errors.NoOfCodes ? (
                <FormFeedback type="invalid">
                  {validation.errors.NoOfCodes}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Body
              </Label>
              <Input
                id="Body"
                className="form-control"
                type="textarea"
                placeholder="Body"
                value={
                  props.selectedCallCenter
                    ? props.selectedCallCenter.body
                    : body
                }
                onChange={(e) => setBody(e.target.value)}
              />
              {validation.touched.body && validation.errors.body ? (
                <FormFeedback type="invalid">
                  {validation.errors.body}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <div className="col-4">
          <button
            className="btn btn-primary"
            type={loadingSubmit ? null : "Add Users"}
            onClick={handleSubmit}
          >
            {loadingSubmit ? (
              <Spinner size={"sm"} color="secondary" />
            ) : (
              "Add Users"
            )}
          </button>
        </div>
        <div className="col-4">
          <button
            className="btn btn-primary"
            type={loadingSubmit1 ? null : "Add Users"}
            onClick={handleSubmit1}
          >
            {loadingSubmit ? (
              <Spinner size={"sm"} color="secondary" />
            ) : (
              "Send to Users"
            )}
          </button>
        </div>
        <div className="col-4">
          <button
            className="btn btn-primary"
            type={loadingSubmit2 ? null : "Send To All"}
            onClick={handleSubmit2}
          >
            {loadingSubmit ? (
              <Spinner size={"sm"} color="secondary" />
            ) : (
              "Send To All"
            )}
          </button>
        </div>
      </Form>
    </>
  )
}
AddEditCallCenter.propTypes = {
  callCenter: PropTypes.any,
  selectedCallCenter: PropTypes.any,
  setCallCenter: PropTypes.any,
  setSelectedCallCenter: PropTypes.any,
  setReload: PropTypes.any,
  showStd1: PropTypes.any,
  setShowStd1: PropTypes.any,
  userList: PropTypes.any,
  setUserList: PropTypes.any,
}

export default AddEditCallCenter
