/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Spinner,
} from "reactstrap"
import { Link } from "react-router-dom"

import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core"
import axios from "axios/axios.js"
import { MDBDataTable } from "mdbreact"
import Pages404 from "pages/Utility/pages-404.js"
import Pages401 from "pages/Utility/pages-401.js"
import AddEditSubCategory from "./AddEditSubCategory.js"

const SubCategories = () => {
  const columns = [
    {
      label: "Name",
      field: "name",
      sort: "asc",
    },
    {
      label: "Name (fr)",
      field: "nameFr",
      sort: "asc",
    },
    {
      label: "Name (it)",
      field: "nameIt",
    },
    {
      label: "Category",
      field: "category",
    },
    {
      label: "",
      field: "actions",
    },
  ]
  const subCategoryInitial = {
    name: "",
    nameFr: "",
    nameIt: "",
    category: null,
  }
  const [menu, setMenu] = useState(false)
  const [reload, setReload] = useState(false)
  const [subCategories, setSubCategories] = useState([])
  const [categories, setCategories] = useState([])
  const [subCategory, setSubCategory] = useState(subCategoryInitial)
  const [selectedSubCategory, setSelectedSubCategory] = useState()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios
      .get("products/categories", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        //(res.data)
        setCategories(
          res.data.map((one) => {
            return {
              value: one._id,
              label: one.name,
            }
          })
        )
      })
      .catch((e) => {
        //(e)
        setError(true)
      })
  }, [])

  useEffect(() => {
    setError(false)
    axios
      .get("products/subCategories", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        //(res.data)
        setError(false)
        setLoading(false)
        setSubCategories(res.data)
      })
      .catch((e) => {
        //(e)
        setLoading(false)
        setError(true)
      })
  }, [reload])

  const handleDelete = (id) => {
    let confirm = window.confirm(
      "You are about delete this sub category, do you wish to continue?"
    )
    if (confirm) {
      axios
        .delete(`products/deleteSubCategory/${id}`, {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          //(res)
          setReload(!reload)
        })
        .catch((e) => {
          //(e)
        })
    }
  }
  return loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container
          fluid
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size={"lg"} color="primary" />
        </Container>
      </div>
    </React.Fragment>
  ) : error ? (
    <Pages401 />
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h5 className="page-title">Sub Categories</h5>
              </Col>
            </Row>
          </div>

          <Row className="mb-4">
            <Col>
              <Accordion expanded={menu} style={{ boxShadow: "none" }}>
                <AccordionSummary
                  style={{ backgroundColor: "#E9ECEF" }}
                  onClick={() => {
                    setMenu(!menu)
                  }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h6
                    style={{
                      fontWeight: "bold",
                      color: "#5B626B",
                    }}
                  >
                    {selectedSubCategory
                      ? "Edit Sub Category"
                      : "Add Sub Category"}
                  </h6>
                </AccordionSummary>
                <AccordionDetails>
                  <AddEditSubCategory
                    categories={categories}
                    selectedSubCategory={selectedSubCategory}
                    setSelectedSubCategory={setSelectedSubCategory}
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                    setReload={setReload}
                  />
                </AccordionDetails>
              </Accordion>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h6 style={{ fontWeight: "bold" }}>Sub Categories List</h6>
                </CardHeader>
                <CardBody>
                  <MDBDataTable
                    hover
                    responsive
                    noBottomColumns
                    data={{
                      columns: columns,
                      rows: subCategories.map((one) => {
                        return {
                          ...one,
                          category:
                            one.categoryInfo && one.categoryInfo[0].name,
                          actions: (
                            <Row
                            // style={{
                            //     display: "flex",
                            //     justifyContent:
                            //         "space-evenly",
                            // }}
                            >
                              <Col xs={4} md={2}>
                                <Link
                                  to="#"
                                  className="btn btn-danger btn-sm mr-2"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    handleDelete(one._id)
                                  }}
                                >
                                  <i className="fas fa-trash"></i>
                                </Link>
                              </Col>
                              <Col xs={4} md={2}>
                                <Link
                                  to="#"
                                  className="btn btn-primary btn-sm"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    if (
                                      selectedSubCategory &&
                                      selectedSubCategory._id === one._id
                                    ) {
                                      setSubCategory(subCategoryInitial)
                                      setSelectedSubCategory(null)
                                    } else {
                                      setSubCategory({
                                        ...one,
                                        category: one.category._id,
                                      })
                                      setSelectedSubCategory({
                                        ...one,
                                        category: one.category._id,
                                      })
                                      setMenu(true)
                                    }
                                  }}
                                >
                                  {selectedSubCategory &&
                                  selectedSubCategory._id === one._id ? (
                                    <i
                                      className="ion ion-md-close"
                                      style={{
                                        color: "white",
                                      }}
                                    ></i>
                                  ) : (
                                    <i className="fas fa-edit"></i>
                                  )}
                                </Link>
                              </Col>
                            </Row>
                          ),
                        }
                      }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SubCategories.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(SubCategories)
