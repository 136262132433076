/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  Alert,
} from "reactstrap"
import { Link } from "react-router-dom"
import check from "../../assets/images/checked.png"
import del from "../../assets/images/delete.png"
import ZoomableImage from "components/Common/ZoomableImage"
import "chartist/dist/scss/chartist.scss"

import { withTranslation } from "react-i18next"
import axios from "axios/axios.js"
import { MDBDataTable } from "mdbreact"
import { Spinner } from "reactstrap"
const Users = () => {
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [edit, setEdit] = useState(false)
  const exportCSV = () => {
    setLoadingSubmit(true)
    axios({
      url: "admins/exportUsers", // Replace with your backend URL
      method: "GET",
      responseType: "blob", // Specify the response type as blob
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "Users.xlsx")
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setLoadingSubmit(false)
      })
      .catch((error) => {
        console.error("Error downloading the Excel file:", error)
      })
  }
  const [carModel, setCarModel] = useState([])
  const [showStd, setShowStd] = useState(false)
  const handleCloseStd = () => setShowStd(!showStd)
  const [showStd1, setShowStd1] = useState(false)
  const handleCloseStd1 = () => setShowStd1(!showStd1)
  const [licenceImg, setLicenseImg] = useState("")
  const [useQrs, setUserQrs] = useState()
  const [checking, setChecking] = useState("")
  const columns = [
    {
      label: "Name",
      field: "username",
      sort: "asc",
    },
    {
      label: "Email",
      field: "email",
      sort: "asc",
    },
    {
      label: "Phone Number",
      field: "phone",
      sort: "asc",
    },
    {
      label: "Car Brand",
      field: "brands",
      sort: "asc",
    },
    {
      label: "Car Model",
      field: "makes",
      sort: "asc",
    },

    {
      label: "License Verfication",
      field: "verf",
      sort: "asc",
    },
    {
      label: "Governorate",
      field: "governorate",
      sort: "asc",
    },
    {
      label: "",
      field: "actions",
    },
  ]
  const [reload, setReload] = useState(false)
  const [error, setError] = useState("")
  const [users, setUsers] = useState([
    {
      username: "",
      email: "",
      phone: "",
      brand: [],
      make: [],
      fImage: [],
      bImage: [],
      dataApproved: [],
      qrs: [],
    },
  ])

  useEffect(() => {
    axios
      .get("admins/allUsers/", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        //(res.data)
        let b = []
        let m = []
        let fImg = []
        let bImg = []
        let dataApproved = []
        let qrs = []
        setUsers(
          res.data.users.map((one) => {
            b = []
            fImg = []
            bImg = []
            dataApproved = []
            qrs = []
            one.qrCode.map((e) => {
              b.push(e.model && e.model.name)
              fImg.push(e.frontImage)
              bImg.push(e.backImage)
              dataApproved.push(e.dataApproved)
              qrs.push(e._id)
            })
            m = []
            one.qrCode.map((ma) => {
              m.push(ma.model && ma.model.make.name)
            })
            return {
              _id: one._id,
              username: one.username,
              email: one.email,
              phone: one.phone,
              brand: b,
              make: m,
              fImage: fImg,
              bImage: bImg,
              dataApproved: dataApproved,
              qrs: qrs,
              banned: one.banned,
              governorate: one.governorate && one.governorate.name,
            }
          })
        )
      })
      .catch((e) => {
        //(e)
      })
  }, [reload])

  const handleBan = (id) => {
    let confirm = window.confirm(
      "You are about ban/unban this user, do you wish to continue?"
    )
    if (confirm) {
      axios
        .patch(
          `admins/banUser/${id}`,
          {},
          {
            headers: {
              Authorization: sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          //(res)
          setReload(!reload)
        })
        .catch((e) => {
          //(e)
        })
    }
  }
  const handleEditLicense = (id, checking1) => {
    axios
      .patch(
        `qrCode/verifyCarData/${id}`,
        {
          dataApproved: checking1,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        //(res)
        setReload(!reload)
        setShowStd(false)
      })
      .catch((e) => {
        //(e)
      })
  }

  const handleDelete = (id) => {
    let confirm = window.confirm(
      "You are about delete this user, do you wish to continue?"
    )
    if (confirm) {
      axios
        .delete(`admins/deleteUser/${id}`, {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          setReload(!reload)
        })
        .catch((e) => {
          //(e)
          setError(e.response.data.message)
        })
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h5 className="page-title">Users</h5>
              </Col>
            </Row>
          </div>

          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h6 style={{ fontWeight: "bold" }}>Users List</h6>
                </CardHeader>
                <CardBody>
                  <div className="col-12">
                    <button
                      className="btn btn-primary"
                      style={{ marginBottom: "3vh" }}
                      type={loadingSubmit ? null : "Export"}
                      onClick={exportCSV}
                    >
                      {loadingSubmit ? (
                        <Spinner size={"sm"} color="secondary" />
                      ) : (
                        <>Export</>
                      )}
                    </button>
                  </div>
                  {error && (
                    <Alert color="danger" className="mb-4 mt-0">
                      <strong>!</strong> {error}
                    </Alert>
                  )}
                  <MDBDataTable
                    hover
                    responsive
                    noBottomColumns
                    data={{
                      columns: columns,
                      rows: users.map((one) => {
                        return {
                          ...one,
                          phone: one.phone,
                          brands: (
                            <>
                              {/* {//(one.brand)} */}
                              <Row>
                                {one.brand.map((b, index) => {
                                  return <Row key={index}>{b}</Row>
                                })}
                              </Row>
                            </>
                          ),
                          makes: (
                            <>
                              <Row>
                                {one.make.map((b, index) => {
                                  return (
                                    <Row key={index}>
                                      <Col>{b}</Col>
                                    </Row>
                                  )
                                })}
                              </Row>
                            </>
                          ),
                          // fimage: (
                          //   <>
                          //     <Row>
                          //       {one.fImage.map((b, index) => {
                          //         return (
                          //           <Row key={index}>
                          //             <Col>
                          //               <img
                          //                 src={b}
                          //                 alt=""
                          //                 style={{
                          //                   maxHeight: "15vh",
                          //                   marginBottom: "1vh",
                          //                 }}
                          //               ></img>
                          //             </Col>
                          //           </Row>
                          //         )
                          //       })}
                          //     </Row>
                          //   </>
                          // ),
                          // bimage: (
                          //   <>
                          //     <Row>
                          //       {one.bImage.map((b, index) => {
                          //         return (
                          //           <Row key={index}>
                          //             <Col>
                          //               <img
                          //                 src={b}
                          //                 alt=""
                          //                 style={{
                          //                   maxHeight: "15vh",
                          //                   marginBottom: "1vh",
                          //                 }}
                          //               ></img>
                          //             </Col>
                          //           </Row>
                          //         )
                          //       })}
                          //     </Row>
                          //   </>
                          // ),
                          verf: (
                            <>
                              {one.qrs.length ? (
                                <Row>
                                  <Col md={6} xs={12}>
                                    <Link
                                      to="#"
                                      className={"btn btn-primary btn-sm"}
                                      onClick={(e) => {
                                        e.preventDefault()
                                        setUserQrs(one)
                                        //(one)
                                        setShowStd(true)
                                      }}
                                    >
                                      <i className="fas fa-qrcode"></i>
                                      {/* <i className="fas fa-check"></i> */}
                                    </Link>
                                  </Col>
                                </Row>
                              ) : (
                                ""
                              )}
                            </>
                          ),
                          address: one.address
                            ? one.address.apartment +
                              ", " +
                              one.address.street +
                              ", " +
                              one.address.city +
                              ", " +
                              one.address.country +
                              ", " +
                              one.address.zipCode
                            : "",
                          actions: (
                            <Row
                              style={{
                                paddingLeft: "1vw",
                                paddingRight: "1vw",
                              }}
                            >
                              <Col md={6} xs={12}>
                                <Link
                                  to="#"
                                  className={
                                    one.banned
                                      ? "btn btn-success btn-sm"
                                      : "btn btn-danger btn-sm"
                                  }
                                  style={{
                                    marginLeft: "0.2vw",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    handleBan(one._id)
                                  }}
                                >
                                  {one.banned ? (
                                    <i className="fas fa-check"></i>
                                  ) : (
                                    <i className="fas fa-ban"></i>
                                  )}
                                </Link>
                              </Col>
                              <Col md={6} xs={12}>
                                <Link
                                  to="#"
                                  className="btn btn-danger btn-sm"
                                  style={{
                                    marginLeft: "0.2vw",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    handleDelete(one._id)
                                  }}
                                >
                                  <i className="fas fa-trash"></i>
                                </Link>
                              </Col>
                            </Row>
                          ),
                        }
                      }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={showStd}
        toggle={handleCloseStd}
        className="my-modal"
        centered
      >
        {" "}
        <ModalBody>
          {useQrs && (
            <Row>
              {useQrs.dataApproved.map((b, index) => {
                return (
                  <Row key={index}>
                    <Col>
                      <img
                        src={useQrs.fImage[index]}
                        alt=""
                        style={{
                          maxHeight: "15vh",
                          marginBottom: "1vh",
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          setLicenseImg(useQrs.fImage[index])
                          setShowStd1(true)
                        }}
                      ></img>
                    </Col>
                    <Col>
                      <img
                        src={useQrs.bImage[index]}
                        alt=""
                        style={{
                          maxHeight: "15vh",
                          marginBottom: "1vh",
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          setLicenseImg(useQrs.bImage[index])
                          setShowStd1(true)
                        }}
                      ></img>
                    </Col>
                    <Col>
                      {b == "unchecked" ? (
                        <Row
                          key={index}
                          style={{
                            height: "15vh",
                            marginBottom: "1vh",
                          }}
                        >
                          <Col>
                            <img
                              src={check}
                              alt=""
                              style={{
                                width: "2vw",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setChecking("yes")
                                handleEditLicense(useQrs.qrs[index], "yes")
                              }}
                            ></img>
                          </Col>
                          <Col>
                            <img
                              src={del}
                              alt=""
                              style={{
                                width: "2vw",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setChecking("no")
                                handleEditLicense(useQrs.qrs[index], "no")
                              }}
                            ></img>
                          </Col>
                        </Row>
                      ) : (
                        <Row
                          key={index}
                          style={{
                            height: "15vh",
                            marginBottom: "1vh",
                            color: b == "yes" ? "green" : "red",
                          }}
                        >
                          {b == "yes" ? (
                            <Row
                              key={index}
                              style={{
                                height: "15vh",
                                marginBottom: "1vh",
                              }}
                            >
                              <Col>{"Accepted"}</Col>
                              <Col>
                                <img
                                  src={del}
                                  alt=""
                                  style={{
                                    width: "2vw",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setChecking("no")
                                    handleEditLicense(useQrs.qrs[index], "no")
                                  }}
                                ></img>
                              </Col>
                            </Row>
                          ) : (
                            <Row
                              key={index}
                              style={{
                                height: "15vh",
                                marginBottom: "1vh",
                              }}
                            >
                              <Col>
                                <img
                                  src={check}
                                  alt=""
                                  style={{
                                    width: "2vw",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setChecking("yes")
                                    handleEditLicense(useQrs.qrs[index], "yes")
                                  }}
                                ></img>
                              </Col>
                              <Col>{"Declined"}</Col>
                            </Row>
                          )}
                        </Row>
                      )}
                    </Col>
                  </Row>
                )
              })}
            </Row>
          )}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showStd1}
        toggle={handleCloseStd1}
        className="my-modal"
        centered
      >
        {" "}
        <ModalBody>
          <ZoomableImage imageUrl={licenceImg} />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

Users.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Users)
