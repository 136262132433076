import styled from "styled-components"

export const ModelcolorEN = styled.div`
  width: 40%;
`
export const ModelcolorAR = styled.div`
  width: 40%;

  margin-left: 65px;
`
